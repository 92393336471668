import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chase-spinner',
  standalone: true,
  imports: [],
  templateUrl: './chase-spinner.component.html',
  styleUrl: './chase-spinner.component.scss',
})
export class ChaseSpinnerComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
}
