import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixCloseFill, remixCheckboxCircleFill } from '@ng-icons/remixicon';

@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [
    provideIcons({
      remixCloseFill,
      remixCheckboxCircleFill,
    }),
  ],
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent implements OnChanges {
  @Input({ required: true }) data!: string[];
  @Input({ required: true }) clear!: boolean;

  @Output() readonly selectedLists = new EventEmitter<string[]>();
  selected: string[] = [];
  state: boolean = false;

  ngOnChanges(): void {
    if (this.clear) this.selected = [];
  }

  toggleSelect() {
    this.state = !this.state;
  }

  closeOnBlur(e: FocusEvent) {
    const target = (e.relatedTarget as HTMLElement) || { id: '' };

    if (target.id == 'listselect') return;
    else this.state = false;
  }

  setSelected(list: string) {
    if (this.selected.includes(list)) {
      this.removeItem(list);

      return;
    }

    this.selected = [...this.selected, list];

    this.selectedLists.emit(this.selected);

    this.state = false;
  }

  removeItem(list: string) {
    const index = this.selected.indexOf(list);

    const firstArr = this.selected.slice(0, index);
    const secondArr = this.selected.slice(index + 1);

    this.selected = firstArr.concat(secondArr);

    this.selectedLists.emit(this.selected);
  }
}
