<!-- Page Header -->
<h1 class="text-2xl font-light text-secondary-900 mb-8">Overview</h1>

<div class="space-y-8">
    <app-dashboard-cards [metrics]="metrics"></app-dashboard-cards>

    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <app-revenue-chart [metrics]="metrics"></app-revenue-chart>
        <app-top-searches></app-top-searches>
    </div>
</div>