import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { PageLayout } from './../types';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { CenteredLayoutComponent } from './layouts/centered-layout/centered-layout.component';
import { LayoutService } from './layouts/layout.service';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageSpinnerComponent } from './ui/spinners/page-spinner/page-spinner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    BlankLayoutComponent,
    CenteredLayoutComponent,
    MainLayoutComponent,
    PageSpinnerComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  readonly PageLayout = PageLayout;
  loading: boolean = true;

  constructor(public layoutService: LayoutService, private router: Router) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
        case event instanceof GuardsCheckStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
        case event instanceof GuardsCheckEnd: {
          this.loading = false;
          break;
        }
        
        default: {
          break;
        }
      }
    });
  }
}
