import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { isPlatformBrowser, NgIf } from '@angular/common';
import * as d3 from 'd3';
import { Metrics } from '../../../pages/dashboard/dashboard.component';

type SortedData = { month: Date; revenue: number };

@Component({
  selector: 'app-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.scss'],
  imports: [NgIf],
  standalone: true,
})
export class RevenueChartComponent implements OnInit {
  @Input() metrics: Metrics | null
  @ViewChild('chart', { static: true })
  chartElement!: ElementRef;


  dimensions = {
    width: 600,
    height: 350,
    marginTop: 20
  };

  data: { month: string; revenue: number }[] = [
    { month: '2024-01-01', revenue: 0 },
    { month: '2024-02-01', revenue: 0 },
    { month: '2024-03-01', revenue: 0 },
    { month: '2024-04-01', revenue: 0 },
    { month: '2024-05-01', revenue: 0 },
    { month: '2024-06-01', revenue: 0 },
    { month: '2024-07-01', revenue: 0 },
    { month: '2024-08-01', revenue: 0 },
    { month: '2024-09-01', revenue: 0 },
    { month: '2024-10-01', revenue: 0 },
    { month: '2024-11-01', revenue: 0 },
    { month: '2024-12-01', revenue: 0 },
  ];
  sortedData: SortedData[];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;

    if (isPlatformBrowser(this.platformId)) {
      this.sortedData = this.data
        .map((d) => {
          return {
            ...d,
            month: new Date(d.month),
          };
        })
        /* Sort in ascending order */
        .sort((a, b) => d3.ascending(a.month, b.month));
    }
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return 
    const wrapper = d3.select('[data-wrapper]');

    const svg = wrapper
      /* Select the `figure` */
      .select('[data-chart]')
      /* Append the SVG */
      .append('svg')
      .attr('class', 'revenue-chart')
      .attr(
        'viewBox',
        `0 0 ${this.dimensions.width} ${this.dimensions.height}`
      );

    const xDomain = [
      this.xAccessor(this.sortedData[0]),
      this.xAccessor(this.sortedData[this.sortedData.length - 1]),
    ];

    const xScale = d3
      .scaleTime()
      .domain(xDomain)
      .range([0, this.dimensions.width]);

    const yDomain = [
      0,
      d3.max(this.sortedData, this.yAccessor),
    ] as d3.NumberValue[];

    const yScale = d3
      .scaleLinear()
      .domain(yDomain)
      .range([this.dimensions.height, this.dimensions.marginTop]);

    const areaGenerator = d3
      .area()
      .x((d) => xScale(this.xAccessor(d)) as number)
      .y1((d) => yScale(this.yAccessor(d)) as number)
      .y0(this.dimensions.height)
      .curve(d3.curveLinear);

    const lineGenerator = d3
      .line()
      .x((d) => xScale(this.xAccessor(d)) as number)
      .y((d) => yScale(this.yAccessor(d)) as number)
      .curve(d3.curveLinear);

    const line = svg
      /* Append `path` */
      .append('path')
      /* Bind the data */
      .datum(this.sortedData)
      /* Pass the generated line to the `d` attribute */
      .attr('d', lineGenerator as any)
      /* Set some styles */
      .attr('stroke', 'hsl(173 82% 46%)')
      .attr('stroke-width', 5)
      .attr('stroke-linejoin', 'round')
      .attr('fill', 'none')

    const area = svg
      .append('path')
      .datum(this.sortedData)
      .attr('d', areaGenerator as any)
      .attr('fill', 'hsl(173 82% 56% / 0.2)');

    const markerLine = svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', this.dimensions.height)
      .attr('stroke-width', 2)
      .attr('stroke', 'white')
      .attr('opacity', 0);

    const markerDot = svg
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)
      .attr('fill', 'white')
      .attr('opacity', 0);

    const bisect = d3.bisector(this.xAccessor);

    document
      .querySelector('.revenue-chart')
      ?.addEventListener('mousemove', (e) => {
        // console.log(e)
        // const pointerCoords = d3.mouse(e.target as d3.ContainerElement);
        // console.log(pointerCoords);
        const { offsetX, offsetY } = e as MouseEvent;
        

        const date = xScale.invert(offsetX);

        const index = bisect.left(this.sortedData, date);
        const d = this.sortedData[index];

        const x = xScale(this.xAccessor(d)) as number;
        const y = yScale(this.yAccessor(d)) as number;

        markerLine.attr('x1', x).attr('x2', x).attr('opacity', 1);

        markerDot.attr('cx', x).attr('cy', y).attr('opacity', 1);

        d3.select('[data-heading]').text(this.getText(this.sortedData, d));

        d3.select('[data-total]').text(this.yAccessor(d));
      });

    document
      .querySelector('.revenue-chart')
      ?.addEventListener('mouseleave', (e) => {
        const lastDatum = this.sortedData[this.sortedData.length - 1];

        /* Hide the markers */
        markerLine.attr('opacity', 0);
        markerDot.attr('opacity', 0);

        /* Reset the text to show latest value */
        d3.select('[data-heading]').text('Revenue');
        d3.select('[data-total]').text(this.yAccessor(lastDatum));
      });
  }

  xAccessor = (d: any) => d.month;
  yAccessor = (d: any) => d.revenue;

  formatDate = d3.timeFormat('%B, %d, %Y');

  getText = (data: SortedData[], d: any) => {
    /* Current Month */
    const current = this.xAccessor(d);

    return `${this.formatDate(current)}`;
  };
}
