<div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
    <div class="rounded-t mb-0 px-2 py-3 border-0 bg-secondary-700">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-bold text-sm text-secondary-50 uppercase">Top Searches</h3>
            </div>
            <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                <button
                    class="bg-primary-500 text-white active:bg-primary-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">View all</button>
            </div>
        </div>
    </div>

    <div class="block w-full overflow-x-auto">
        <table class="items-center bg-transparent w-full border-collapse ">
            <thead>
                <tr>
                    <th
                        class="px-6 bg-secondary-50 text-secondary-500 align-middle border border-solid border-secondary-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Rank
                    </th>
                    <th
                        class="px-6 bg-secondary-50 text-secondary-500 align-middle border border-solid border-secondary-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Search Term
                    </th>
                    <th
                        class="px-6 bg-secondary-50 text-secondary-500 align-middle border border-solid border-secondary-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Frequency
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of data">
                    <th
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left text-secondary-700 ">
                        {{ item.rank }}
                    </th>
                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 ">
                        {{item.search_term}}
                    </td>
                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        {{item.frequency}}
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</div>