import { NgFor } from '@angular/common';
import { Component } from '@angular/core';

const sample_data = [
  {
    rank: 1,
    search_term: '',
    frequency: 0,
  },
];

@Component({
  selector: 'app-top-searches',
  standalone: true,
  imports: [NgFor],
  templateUrl: './top-searches.component.html',
})
export class TopSearchesComponent {
  data: {
    rank: number;
    search_term: string;
    frequency: number;
  }[] = sample_data;
}
