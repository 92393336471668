import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEyeCloseFill, remixEyeFill } from '@ng-icons/remixicon';
import { ChaseSpinnerComponent } from '../../ui/spinners/chase-spinner/chase-spinner.component';
import { AuthService } from '../../services/auth.service';
import { FormsModule } from '@angular/forms';
import { UserStoreService } from '../../services/user-store.service';
import { ToastrService } from 'ngx-toastr';

interface FormModel {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NgIconComponent,
    RouterLink,
    ChaseSpinnerComponent,
    NgIf,
    FormsModule,
  ],
  providers: [provideIcons({ remixEyeFill, remixEyeCloseFill })],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  loading = false;
  showPassword = false;

  form: FormModel = {
    email: '',
    password: '',
  };

  constructor(private authService: AuthService, private userStoreService: UserStoreService, private toastr: ToastrService, private router: Router) {}

  login() {
    this.setLoading(true);

    this.authService.login(this.form.email, this.form.password).subscribe({
      next: data => {
        this.userStoreService.saveUser(data)

        this.router.navigateByUrl('/')

        this.setLoading(false)

      },
      error: err => {

        this.toastr.error(err, 'Authentication error')

        this.userStoreService.clean()

        this.setLoading(false)
      }
    })
  }

  setLoading(state: boolean) {
    setTimeout(() => (this.loading = state), 500);
  }

  toggleShowPassword(el: HTMLInputElement) {
    this.showPassword = !this.showPassword;

    el.type = this.showPassword ? 'text' : 'password';
  }
}
