import { NgClass } from '@angular/common';
import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEyeLine, remixEdit2Line, remixArrowUpCircleFill } from '@ng-icons/remixicon';
import { createPopper } from '@popperjs/core';
import { Client } from '../../../types';

@Component({
  selector: 'app-ctable-dropdown',
  standalone: true,
  imports: [NgClass, NgIconComponent],
  providers: [provideIcons({ remixEyeLine, remixEdit2Line, remixArrowUpCircleFill })],
  templateUrl: './ctable-dropdown.component.html',
})
export class CtableDropdownComponent implements AfterViewInit {
  dropdownPopoverShow = false;

  @Input({ required: true }) user!: Client
  @Output() deactivateAccount = new EventEmitter<{ state: boolean, user: Client }>();

  @ViewChild('btnDropdownRef', { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild('popoverDropdownRef', { static: true })
  popoverDropdownRef!: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: 'bottom-start',
      }
    );
  }

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  closePopover(event: FocusEvent) {
    const target = (event.relatedTarget as HTMLElement) || null;

    if (target && target.classList.contains('popel')) {
      return;
    }

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    }
  }

  emitDeactivate() {
    this.deactivateAccount.emit({ state: true , user: this.user});

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    }
  }
}
