import { NgFor, NgIf } from '@angular/common';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
  remixEyeFill,
  remixWallet3Line,
  remixEyeLine
} from '@ng-icons/remixicon';
import { WfilterConfig, Portfolio } from '../../../types';
import { portfoliosData } from '../../../portfolios-data';

import { DialogComponent } from '../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../ui/list-select/list-select.component';

@Component({
  selector: 'app-portfolios',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    FormsModule,
    DialogComponent,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
      remixEyeFill,
      remixWallet3Line,
      remixEyeLine
    }),
  ],
  templateUrl: './portfolios.component.html',
})
export class PortfoliosComponent {

  currentPortfolio?: Portfolio
  open: boolean = false;
  data: Portfolio[] = portfoliosData;
  portfolios: Portfolio[] = portfoliosData;

  searchLocale: 'all' | 'email' | 'name' | 'username' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username'];
  tableFilters: WfilterConfig = {
    status: [],
  };

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'username';
  }

  openPortfoliosodal(value: { state: boolean; user: Portfolio}) {
    this.open = value.state;
    this.currentPortfolio = value.user;
  }

  closeModal() {
    this.open = false;
  }

  setFilters(filter: WfilterConfig) {
    this.tableFilters = filter;
  }
}
