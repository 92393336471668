import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixStarHalfFill,
  remixStarFill,
  remixStarLine,
} from '@ng-icons/remixicon';

@Component({
  selector: 'app-star-rating',
  standalone: true,
  imports: [NgFor, NgIf, NgIconComponent],
  providers: [
    provideIcons({ remixStarHalfFill, remixStarFill, remixStarLine }),
  ],
  templateUrl: './star-rating.component.html',
})
export class StarRatingComponent {
  @Input({ required: true }) value!: number;
}
