<div id="sidebar-menu" class="fixed left-0 top-0 h-full bg-secondary-950 p-4 z-40 transition-all ease-in-out"
  [ngClass]="collapse ? 'w-20' : 'w-64'">
  <a href="#" class="flex items-center pb-4 border-b border-b-secondary-600"
    [ngClass]="collapse ? 'items-center justify-center' : 'items-center'">
    <img [src]="collapse ? '../../../assets/icon.png' : '../../../assets/ngd-logo.png'" alt="Ngd Logo"
      class="w-auto rounded object-cover brightness-0 invert-[1]" [ngClass]="collapse ? 'h-7' : 'h-8'" />
    <!-- <span class="text-lg font-light text-white ml-3">Admin</span> -->
  </a>

  <ul class="mt-4">
    <app-sidebar-link (emitClick)="openDrop($event)" [collapse]="collapse" *ngFor=" let page of pages"
      [page]="page"></app-sidebar-link>
  </ul>

  <button
    class="absolute bottom-0 left-0 bg-primary-500 h-9 w-9 hidden md:flex items-center justify-center rounded-tr-md"
    (click)="toggleCollapse()">
    <ng-icon [ngClass]="collapse ? 'rotate-180' : 'rotate-0'" size="1.25rem" class="text-primary-50 transition-all" name="remixContractLeftLine"></ng-icon>
  </button>

</div>
<div id="sidebar-overlay" (click)="toggleSidebarOverlay($event)"
  class="fixed top-0 left-0 w-full h-full bg-black/50 z-30 md:hidden"></div>