import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixMenuLine,
  remixNotification2Line,
  remixSearchLine,
  remixLogoutBoxLine,
} from '@ng-icons/remixicon';
import { Page } from '../../../types';
import { LastActivePageService } from '../../services/last-active-page.service';
import { AuthUser, UserStoreService } from '../../services/user-store.service';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [NgIconComponent, RouterLink, CommonModule],
  providers: [
    provideIcons({
      remixMenuLine,
      remixSearchLine,
      remixNotification2Line,
      remixLogoutBoxLine,
    }),
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent implements OnInit {
  user?: AuthUser['user']

  @Input({ required: true }) pages!: Page[];
  @Input({ required: true }) collapse: boolean = false;
  @Output() emitCollapse = new EventEmitter<boolean>();

  // pTitle?: string;
  // sTitle?: string;
  // parentPageExists: boolean = false

  constructor(
    private router: Router,
    private lastActivePageService: LastActivePageService,
    private userStoreService: UserStoreService
  ) {
    this.user = userStoreService.getUser()?.user
  }

  ngOnInit(): void {
    /* const parentHref = '/' + this.router.url.split('/')[1];
    const subHref = '/' + this.router.url.split('/')[2];

    const parentPage = this.pages.find((v) => v.link == parentHref);

    this.pTitle = parentPage?.title;

    if (parentPage?.subPages) {
      this.parentPageExists = true
      this.sTitle = parentPage?.subPages.find((s) => s.link == parentHref + subHref)?.title;
    } */
  }

  sidebarToggle(e: MouseEvent): void {
    const main = document.querySelector('#dashboard-main')!;
    const sidebarOverlay = document.querySelector('#sidebar-overlay')!;
    const sidebarMenu = document.querySelector('#sidebar-menu')!;

    e.preventDefault();
    main.classList.toggle('active');
    sidebarOverlay.classList.toggle('hidden');
    sidebarMenu.classList.toggle('-translate-x-full');
  }

  logout(): void {
    this.lastActivePageService.storeLastActivePage(this.router.url);

    this.userStoreService.clean();

    this.router.navigateByUrl('/login');
  }
}
