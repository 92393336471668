<div class="chart-wrapper bg-gradient-to-br from-secondary-900 to-secondary-700 rounded-md shadow-md" data-wrapper>
  <div class="p-4 flex items-start justify-between">
    <div class="space-y-2">
      <h3 class="uppercase tracking-white text-secondary-300 text-sm font-bold" data-heading>Monthly Revenue</h3>
      <p class="font-light text-3xl text-white" data-total>0</p>
    </div>

    <button
      class="bg-primary-500 text-white active:bg-primary-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button">View all</button>

  </div>
  <figure data-chart></figure>
</div>