<div class="flex items-center gap-2 flex-wrap">

    <div class="flex items-center gap-1">
        <span *ngFor="let num of [1, 2, 3, 4, 5]">
            <ng-icon *ngIf="num - value >= 1; else half" name="remixStarLine" size="1rem"
                class="text-yellow-500"></ng-icon>

            <ng-template #half>
                <ng-icon *ngIf="value > (num - 0.5)" name="remixStarFill" size="1rem" class="text-yellow-500"></ng-icon>
                <ng-icon *ngIf="value < (num - 0.5)" name="remixStarHalfFill" size="1rem"
                    class="text-yellow-500"></ng-icon>
            </ng-template>
        </span>
    </div>

    <div *ngIf="value < 4"
        class="inline px-2 py-1 text-xs font-bold rounded-full text-red-700 gap-x-2 bg-red-100 dark:bg-gray-800">
        {{value}}
    </div>
    <div *ngIf="value > 4"
        class="inline px-2 py-1 text-xs font-bold rounded-full text-yellow-700 gap-x-2 bg-yellow-100 dark:bg-gray-800">
        {{value}}
    </div>
</div>