export const portfoliosData = [
  {
    link: 'https://youdesign.africa/design-works/house-31b',
    image: 'https://api1.ngd.africa/media/uploads/images/Novel_Ngd',
    name: 'House 31b',
  },
  {
    link: 'https://youdesign.africa/design-works/okin-brand-identity',
    image: 'https://api1.ngd.africa/media/uploads/images/3_AG6lDZV',
    name: 'Okin Brand Identity',
  },
  {
    link: 'https://youdesign.africa/design-works/chike-and-the-river',
    image: 'https://api1.ngd.africa/media/uploads/images/cover_o0rzKdv',
    name: 'CHIKE AND THE RIVER',
  },
  {
    link: 'https://youdesign.africa/design-works/sonato',
    image: 'https://api1.ngd.africa/media/uploads/images/Sanoto_2',
    name: 'Sonato',
  },
  {
    link: 'https://youdesign.africa/design-works/hapipau-a-brand-identity-for-a-pet-store',
    image: 'https://api1.ngd.africa/media/uploads/images/1_0yV2R7M',
    name: 'hapipau :- (A brand identity for a pet store)',
  },
  {
    link: 'https://youdesign.africa/design-works/okin-brand-identity-2',
    image: 'https://api1.ngd.africa/media/uploads/images/OKIN_BRAND-01',
    name: 'Okin Brand Identity',
  },
  {
    link: 'https://youdesign.africa/design-works/divine-fantasy',
    image: 'https://api1.ngd.africa/media/uploads/images/fantasy_hood_29JQe8u',
    name: 'Divine fantasy',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity_zubair-clothing',
    image: 'https://api1.ngd.africa/media/uploads/images/zubair_1-01',
    name: 'Brand identity_ZUBAIR CLOTHING',
  },
  {
    link: 'https://youdesign.africa/design-works/cisco-logo-animation-day-02',
    image: 'https://api1.ngd.africa/media/uploads/images/cisco_logo.gif',
    name: 'Cisco logo animation - Day 02',
  },
  {
    link: 'https://youdesign.africa/design-works/ngd-brochure-design-beginner-tier-bunny-pastries-and-sweetness-2',
    image: 'https://api1.ngd.africa/media/uploads/images/BUNNY_COVER_17f3vec',
    name: 'NGD Brochure Design - Beginner Tier (Bunny Pastries and Sweetness)',
  },
  {
    link: 'https://youdesign.africa/design-works/milly',
    image: 'https://api1.ngd.africa/media/uploads/images/Untitled44',
    name: 'Milly',
  },
  {
    link: 'https://youdesign.africa/design-works/heifer-report',
    image: 'https://api1.ngd.africa/media/uploads/images/Heifer_Report_LO',
    name: 'HEIFER REPORT',
  },
  {
    link: 'https://youdesign.africa/design-works/adidas-billboard-design',
    image: 'https://api1.ngd.africa/media/uploads/images/6c5c2a52369461',
    name: 'adidas billboard design',
  },
  {
    link: 'https://youdesign.africa/design-works/j-franklin-brand-identity',
    image:
      'https://api1.ngd.africa/media/uploads/images/James_Franklin_WoodYou_Spec_7_gF5lsT9',
    name: 'J. Franklin Brand Identity',
  },
  {
    link: 'https://youdesign.africa/design-works/xpress-shipping-co-brand-identity-design',
    image: 'https://api1.ngd.africa/media/uploads/images/xpress_5_Gy7XjQB',
    name: 'Xpress Shipping Co. Brand Identity Design',
  },
  {
    link: 'https://youdesign.africa/design-works/litima-nuhu',
    image: 'https://api1.ngd.africa/media/uploads/images/scholarship',
    name: 'Litima Nuhu',
  },
  {
    link: 'https://youdesign.africa/design-works/3d-modeling-of-abuja-car',
    image: 'https://api1.ngd.africa/media/uploads/images/AbujaCar_',
    name: '3D modeling of Abuja car',
  },
  {
    link: 'https://youdesign.africa/design-works/photo-manipulation',
    image: 'https://api1.ngd.africa/media/uploads/images/amazing_2_BFL5PB5',
    name: 'Photo Manipulation',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-for-star-way-airlines',
    image: 'https://api1.ngd.africa/media/uploads/images/STARWAY1',
    name: 'Brand Identity for Star way Airlines',
  },
  {
    link: 'https://youdesign.africa/design-works/deskie-workspace-illustration-keyboard',
    image:
      'https://api1.ngd.africa/media/uploads/images/workspace_illustration_keyboard_mraxsNf',
    name: 'Deskie: workspace illustration (keyboard)',
  },
  {
    link: 'https://youdesign.africa/design-works/kafeburg-a-brand-identity-for-a-burger-shop',
    image: 'https://api1.ngd.africa/media/uploads/images/10_v8VUH0f',
    name: 'kafeburg:- (A brand identity for a burger shop)',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-for-peckers-football-club',
    image:
      'https://api1.ngd.africa/media/uploads/images/peckers_football_club_1',
    name: 'Brand Identity for Peckers Football Club',
  },
  {
    link: 'https://youdesign.africa/design-works/fashionshopcom-brand-identity-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/FASHION_SHOP-07_Bn9GCd5',
    name: 'FASHIONSHOP.COM - BRAND IDENTITY DESIGN',
  },
  {
    link: 'https://youdesign.africa/design-works/domino-technologies',
    image:
      'https://api1.ngd.africa/media/uploads/images/DOMINO_TECHNOLOGIES_PRESENTATION-04',
    name: 'DOMINO TECHNOLOGIES',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-for-a-nutritionist-nutriti',
    image: 'https://api1.ngd.africa/media/uploads/images/nutriti-08',
    name: 'Brand identity for a Nutritionist NUTRITI',
  },
  {
    link: 'https://youdesign.africa/design-works/the-westerner',
    image: 'https://api1.ngd.africa/media/uploads/images/LOGO_1-02',
    name: 'THE WESTERNER',
  },
  {
    link: 'https://youdesign.africa/design-works/author-matthew',
    image:
      'https://api1.ngd.africa/media/uploads/images/20220120_121706_0g6ItGK',
    name: 'Author Matthew',
  },
  {
    link: 'https://youdesign.africa/design-works/publication-design-challenge-ngd-beginner-tie',
    image:
      'https://api1.ngd.africa/media/uploads/images/inbound4173660077737079349',
    name: 'PUBLICATION DESIGN CHALLENGE - NGD BEGINNER TIE',
  },
  {
    link: 'https://youdesign.africa/design-works/-21',
    image: 'https://api1.ngd.africa/media/uploads/images/1_2',
    name: '',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-and-brand-design-gekko',
    image: 'https://api1.ngd.africa/media/uploads/images/smart_tv',
    name: 'LOGO AND BRAND DESIGN - GEKKO',
  },
  {
    link: 'https://youdesign.africa/design-works/bunny-pastries-brochure-3',
    image:
      'https://api1.ngd.africa/media/uploads/images/AB9AE4AF-7F60-4223-A7CE-673AC5EA4DD6',
    name: 'Bunny Pastries Brochure',
  },
  {
    link: 'https://youdesign.africa/design-works/the-art-of-text-typography-ngd',
    image: 'https://api1.ngd.africa/media/uploads/images/ndg_pssss_3',
    name: 'The art of text: typography (NGD)',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-design-9',
    image: 'https://api1.ngd.africa/media/uploads/images/Junction_2',
    name: 'Logo Design',
  },
  {
    link: 'https://youdesign.africa/design-works/sergeant-rooster',
    image: 'https://api1.ngd.africa/media/uploads/images/character-design-15',
    name: 'Sergeant Rooster',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-design-for-directroute-travel-agency',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG_20220303_190914_059_joHwaNT',
    name: 'Brand Identity Design for DIRECTROUTE Travel Agency',
  },
  {
    link: 'https://youdesign.africa/design-works/shooting-stars',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG_20220323_194107_655',
    name: 'SHOOTING STARS',
  },
  {
    link: 'https://youdesign.africa/design-works/adejumo-toheeb-akanbi',
    image: 'https://api1.ngd.africa/media/uploads/images/DSC_0368_copy',
    name: 'ADEJUMO TOHEEB AKANBI',
  },
  {
    link: 'https://youdesign.africa/design-works/ante-care-identity',
    image: 'https://api1.ngd.africa/media/uploads/images/1_9BhiwuM',
    name: 'ANTE-CARE IDENTITY',
  },
  {
    link: 'https://youdesign.africa/design-works/flyer-designs-5',
    image:
      'https://api1.ngd.africa/media/uploads/images/Profile_2_copy2_OVR1t5B',
    name: 'Flyer Designs',
  },
  {
    link: 'https://youdesign.africa/design-works/your-vote-is-your-power-8',
    image: 'https://api1.ngd.africa/media/uploads/images/20220728_140941',
    name: 'YOUR VOTE IS YOUR POWER',
  },
  {
    link: 'https://youdesign.africa/design-works/budding-designers',
    image: 'https://api1.ngd.africa/media/uploads/images/NGD_Deliverables_1aa',
    name: 'BUDDING DESIGNERS',
  },
  {
    link: 'https://youdesign.africa/design-works/avila-buildings-3',
    image: 'https://api1.ngd.africa/media/uploads/images/1_E86McWP',
    name: 'AVILA BUILDINGS',
  },
  {
    link: 'https://youdesign.africa/design-works/graphic-designs',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG-20221231-WA0114_ZIeIRUk',
    name: 'GRAPHIC DESIGNS',
  },
  {
    link: 'https://youdesign.africa/design-works/nigerian-air',
    image: 'https://api1.ngd.africa/media/uploads/images/9aeb3d68994603',
    name: 'Nigerian Air Identity Design',
  },
  {
    link: 'https://youdesign.africa/design-works/carshop-ng',
    image: 'https://api1.ngd.africa/media/uploads/images/CarShopng_1',
    name: 'CarShop ng',
  },
  {
    link: 'https://youdesign.africa/design-works/mediacracy',
    image: 'https://api1.ngd.africa/media/uploads/images/Mediacracy_2_2P1o3Xb',
    name: 'MediaCracy',
  },
  {
    link: 'https://youdesign.africa/design-works/scolos-brand-identity',
    image: 'https://api1.ngd.africa/media/uploads/images/scolos_1',
    name: 'Scolos Brand Identity',
  },
  {
    link: 'https://youdesign.africa/design-works/dont-look-tshirt-custom',
    image:
      'https://api1.ngd.africa/media/uploads/images/behance_download_1637445179375',
    name: "Don't Look - Tshirt Custom",
  },
  {
    link: 'https://youdesign.africa/design-works/ngd-website-ui-design',
    image: 'https://api1.ngd.africa/media/uploads/images/Gatalog',
    name: 'NGD Website UI design',
  },
  {
    link: 'https://youdesign.africa/design-works/aveon-closet',
    image: 'https://api1.ngd.africa/media/uploads/images/LOGO_MOCKUP',
    name: 'Aveon Closet',
  },
  {
    link: 'https://youdesign.africa/design-works/portal',
    image: 'https://api1.ngd.africa/media/uploads/images/Portal_C4d',
    name: 'Portal',
  },
  {
    link: 'https://youdesign.africa/design-works/zripes-personal-project',
    image: 'https://api1.ngd.africa/media/uploads/images/Frame_4',
    name: 'Zripes (Personal Project)',
  },
  {
    link: 'https://youdesign.africa/design-works/akehinde-vougue',
    image: 'https://api1.ngd.africa/media/uploads/images/mood22_P1JyNUN',
    name: 'Akehinde Vougue',
  },
  {
    link: 'https://youdesign.africa/design-works/talkie-home-assistance-3d-model',
    image: 'https://api1.ngd.africa/media/uploads/images/Talkie1s',
    name: 'Talkie (Home Assistance 3D Model)',
  },
  {
    link: 'https://youdesign.africa/design-works/covid-19-vaccination-booking-concept-mobile-app',
    image: 'https://api1.ngd.africa/media/uploads/images/covid_booking_centre',
    name: 'Covid-19 Vaccination Booking Concept Mobile App',
  },
  {
    link: 'https://youdesign.africa/design-works/joseph-tochi',
    image: 'https://api1.ngd.africa/media/uploads/images/20211128_215735',
    name: 'Joseph Tochi',
  },
  {
    link: 'https://youdesign.africa/design-works/3d-modeling-of-elevator',
    image: 'https://api1.ngd.africa/media/uploads/images/Elevator_system_',
    name: '3D modeling of elevator',
  },
  {
    link: 'https://youdesign.africa/design-works/graphic-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/Micasa_managers_logo_white_xxZuPsS',
    name: 'Graphic Design',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-design-2',
    image: 'https://api1.ngd.africa/media/uploads/images/udar',
    name: 'Logo Design',
  },
  {
    link: 'https://youdesign.africa/design-works/terer-tordue-michael',
    image: 'https://api1.ngd.africa/media/uploads/images/4-6',
    name: 'Terer Tordue Michael',
  },
  {
    link: 'https://youdesign.africa/design-works/church-flyer',
    image: 'https://api1.ngd.africa/media/uploads/images/happy_sunday',
    name: 'church flyer',
  },
  {
    link: 'https://youdesign.africa/design-works/social-media-designs',
    image: 'https://api1.ngd.africa/media/uploads/images/sept202x',
    name: 'Social media designs',
  },
  {
    link: 'https://youdesign.africa/design-works/via',
    image: 'https://api1.ngd.africa/media/uploads/images/VIA_4',
    name: 'VIA',
  },
  {
    link: 'https://youdesign.africa/design-works/quicode',
    image: 'https://api1.ngd.africa/media/uploads/images/Quicode_3',
    name: 'Quicode',
  },
  {
    link: 'https://youdesign.africa/design-works/flier',
    image: 'https://api1.ngd.africa/media/uploads/images/yfp',
    name: 'Flier',
  },
  {
    link: 'https://youdesign.africa/design-works/flyer-2',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG_20211208_120117_883',
    name: 'Flyer',
  },
  {
    link: 'https://youdesign.africa/design-works/-6',
    image:
      'https://api1.ngd.africa/media/uploads/images/inbound3088208619905253736',
    name: '',
  },
  {
    link: 'https://youdesign.africa/design-works/adun-protein-bar',
    image: 'https://api1.ngd.africa/media/uploads/images/Bar_2_Y8gu4cS',
    name: 'Adun Protein Bar',
  },
  {
    link: 'https://youdesign.africa/design-works/identity-design-for-tinuu',
    image: 'https://api1.ngd.africa/media/uploads/images/T2_XSBmRH7',
    name: 'Identity Design for Tinuu',
  },
  {
    link: 'https://youdesign.africa/design-works/energii-batteries-packaging-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/basziola_1609161166190306_PbdDFc8',
    name: 'Energii Batteries Packaging Design',
  },
  {
    link: 'https://youdesign.africa/design-works/fedex-logo-animation',
    image:
      'https://api1.ngd.africa/media/uploads/images/fedex_logo_7Td5oMR.gif',
    name: 'Fedex Logo Animation',
  },
  {
    link: 'https://youdesign.africa/design-works/ayoka-logo-animaton',
    image: 'https://api1.ngd.africa/media/uploads/images/ayoka_logo_1.gif',
    name: 'Ayoka Logo Animaton',
  },
  {
    link: 'https://youdesign.africa/design-works/indeed-logo-animation',
    image:
      'https://api1.ngd.africa/media/uploads/images/indeed_logo_animation.gif',
    name: 'Indeed Logo Animation',
  },
  {
    link: 'https://youdesign.africa/design-works/jiji-logo-animation',
    image:
      'https://api1.ngd.africa/media/uploads/images/jiji_logo_animation_1.gif',
    name: 'jiji logo animation',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-design-for-xerver',
    image: 'https://api1.ngd.africa/media/uploads/images/xerver_1',
    name: 'Brand Identity Design for Xerver',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-design-for-birdlines',
    image:
      'https://api1.ngd.africa/media/uploads/images/InShot_20211214_004804487',
    name: 'Logo Design for Birdlines',
  },
  {
    link: 'https://youdesign.africa/design-works/flyer-design-for-pccf',
    image:
      'https://api1.ngd.africa/media/uploads/images/InShot_20211214_012321968',
    name: 'Flyer Design for PCCF',
  },
  {
    link: 'https://youdesign.africa/design-works/wedding-programme-cover-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/InShot_20211214_012243368',
    name: 'Wedding Programme Cover Design',
  },
  {
    link: 'https://youdesign.africa/design-works/flyer-designs-for-pccf',
    image:
      'https://api1.ngd.africa/media/uploads/images/InShot_20211214_012150888',
    name: 'Flyer Designs for PCCF',
  },
  {
    link: 'https://youdesign.africa/design-works/typographic-logo-concepts',
    image: 'https://api1.ngd.africa/media/uploads/images/catch',
    name: 'Typographic Logo Concepts',
  },
  {
    link: 'https://youdesign.africa/design-works/ckstudios',
    image: 'https://api1.ngd.africa/media/uploads/images/CKS',
    name: 'CkStudios',
  },
  {
    link: 'https://youdesign.africa/design-works/proposed-brand-identity-for-instablog9ja',
    image: 'https://api1.ngd.africa/media/uploads/images/instablog9ja_1',
    name: 'Proposed Brand Identity for Instablog9ja',
  },
  {
    link: 'https://youdesign.africa/design-works/dusk-to-dawn',
    image: 'https://api1.ngd.africa/media/uploads/images/Routine_coffee',
    name: 'Dusk to dawn',
  },
  {
    link: 'https://youdesign.africa/design-works/young-and-rich-social-media-designs',
    image: 'https://api1.ngd.africa/media/uploads/images/YAR004a',
    name: 'Young and Rich Social Media Designs',
  },
  {
    link: 'https://youdesign.africa/design-works/event-ticket-design',
    image: 'https://api1.ngd.africa/media/uploads/images/TICKETS_2021_1k',
    name: 'Event Ticket Design',
  },
  {
    link: 'https://youdesign.africa/design-works/samuel-tosin',
    image: 'https://api1.ngd.africa/media/uploads/images/Scoop_5',
    name: 'Samuel Tosin',
  },
  {
    link: 'https://youdesign.africa/design-works/farmers-tranzacts',
    image: 'https://api1.ngd.africa/media/uploads/images/Farmers_Tranzact-06',
    name: 'Farmers Tranzacts',
  },
  {
    link: 'https://youdesign.africa/design-works/trikas-foods',
    image:
      'https://api1.ngd.africa/media/uploads/images/Trikas_Foods_Presentation-01',
    name: 'TRIKAS FOODS',
  },
  {
    link: 'https://youdesign.africa/design-works/zara',
    image: 'https://api1.ngd.africa/media/uploads/images/Isaac_Zara22-07_Bag',
    name: 'Zara',
  },
  {
    link: 'https://youdesign.africa/design-works/shelly-cuisine',
    image: 'https://api1.ngd.africa/media/uploads/images/SHELLY_CUISINE-04',
    name: 'Shelly Cuisine',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-design-logo-design-for-menas-bite',
    image:
      'https://api1.ngd.africa/media/uploads/images/T-Shirt_Mockup_MockupsForFree_xSeusuT',
    name: "Brand Design & Logo Design For Mena's Bite",
  },
  {
    link: 'https://youdesign.africa/design-works/mush-woods',
    image: 'https://api1.ngd.africa/media/uploads/images/2k_5ew8V8n',
    name: 'Mush woods',
  },
  {
    link: 'https://youdesign.africa/design-works/3d-modeling-4',
    image: 'https://api1.ngd.africa/media/uploads/images/Grass_Land-',
    name: '3D Modeling',
  },
  {
    link: 'https://youdesign.africa/design-works/-12',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG_20211229_013402_139',
    name: '',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-and-branding-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/ACB33B66-482A-497F-A8E1-C0B2F0243B3F',
    name: 'Logo and branding design',
  },
  {
    link: 'https://youdesign.africa/design-works/crown-carrot',
    image:
      'https://api1.ngd.africa/media/uploads/images/IMG_20220105_163908_709',
    name: 'Crown Carrot',
  },
  {
    link: 'https://youdesign.africa/design-works/novel-cover',
    image: 'https://api1.ngd.africa/media/uploads/images/20220106_134656',
    name: 'Novel cover',
  },
  {
    link: 'https://youdesign.africa/design-works/koala-technology-branding',
    image: 'https://api1.ngd.africa/media/uploads/images/KOALA_TECH-01',
    name: 'Koala Technology Branding',
  },
  {
    link: 'https://youdesign.africa/design-works/akumuko-foundation-branding',
    image: 'https://api1.ngd.africa/media/uploads/images/AKUMUKO_BEHNACE-05',
    name: 'Akumuko Foundation Branding',
  },
  {
    link: 'https://youdesign.africa/design-works/sheilas-garden',
    image: 'https://api1.ngd.africa/media/uploads/images/20211129_102753',
    name: "Sheila's garden",
  },
  {
    link: 'https://youdesign.africa/design-works/deskie-workspace-illustration-desktop',
    image:
      'https://api1.ngd.africa/media/uploads/images/workspace_illustration_desktopps_0qVPYN0',
    name: 'Deskie: workspace illustration (desktop).',
  },
  {
    link: 'https://youdesign.africa/design-works/racers-brand-identity-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/RACERS_PRESENTATION-01',
    name: 'RACERS - BRAND IDENTITY DESIGN',
  },
  {
    link: 'https://youdesign.africa/design-works/anticipate2022-a-flyer-for-a-church-program',
    image:
      'https://api1.ngd.africa/media/uploads/images/Asset_11ghjgh-1_dHcil3U',
    name: 'Anticipate2022- A flyer for a church program',
  },
  {
    link: 'https://youdesign.africa/design-works/feragel-a-brand-identity-for-a-fashion-industry',
    image:
      'https://api1.ngd.africa/media/uploads/images/Feragel_A_Brand_Identity_for_a_Fashion_Industry_8_0DxhlJi',
    name: 'Feragel (A Brand Identity for a Fashion Industry)',
  },
  {
    link: 'https://youdesign.africa/design-works/melo-food-campaign-flyer',
    image: 'https://api1.ngd.africa/media/uploads/images/DAY-1_upload',
    name: 'MELO FOOD CAMPAIGN FLYER',
  },
  {
    link: 'https://youdesign.africa/design-works/clay-brand-identity-for-a-jewelry-shop',
    image:
      'https://api1.ngd.africa/media/uploads/images/CLAY_BRAND_IDENTITY_-_display_hSTBkbs',
    name: 'CLAY - (Brand Identity for a jewelry shop)',
  },
  {
    link: 'https://youdesign.africa/design-works/pheonix-motors',
    image: 'https://api1.ngd.africa/media/uploads/images/LOGO_1',
    name: 'PHEONIX MOTORS',
  },
  {
    link: 'https://youdesign.africa/design-works/event-graphics',
    image: 'https://api1.ngd.africa/media/uploads/images/golgotha_evangelism',
    name: 'Event graphics',
  },
  {
    link: 'https://youdesign.africa/design-works/deskie-workspace-illustration-mouse',
    image:
      'https://api1.ngd.africa/media/uploads/images/workspace_illustration_mouseps_new',
    name: 'Deskie: workspace illustration (Mouse).',
  },
  {
    link: 'https://youdesign.africa/design-works/poster-design-for-a-basketball-club',
    image:
      'https://api1.ngd.africa/media/uploads/images/NGD-PORTFOLIO--POSTER-11_HsoerjD',
    name: 'POSTER DESIGN FOR A BASKETBALL CLUB',
  },
  {
    link: 'https://youdesign.africa/design-works/await-a-savethedate-flyer-for-a-wedding',
    image: 'https://api1.ngd.africa/media/uploads/images/Asset_49ghjgh_t6cZtRt',
    name: 'Await! - A SaveTheDate flyer for a wedding',
  },
  {
    link: 'https://youdesign.africa/design-works/logo-design-food-tag',
    image:
      'https://api1.ngd.africa/media/uploads/images/DAY-2---FOOD-TAG-LOGO_YELLOW',
    name: 'LOGO DESIGN - FOOD TAG',
  },
  {
    link: 'https://youdesign.africa/design-works/warshar-product-packaging-label-design-for-a-laundry-company',
    image:
      'https://api1.ngd.africa/media/uploads/images/Warshar_Product_Packaging__Label_Design_For_A_Laundry_Company_-_1',
    name: 'Warshar (Product Packaging & Label Design For A Laundry Company)',
  },
  {
    link: 'https://youdesign.africa/design-works/medicare-maximus-logo-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/MEDICARE_PRESENTATION-01',
    name: 'MEDICARE MAXIMUS - LOGO DESIGN',
  },
  {
    link: 'https://youdesign.africa/design-works/trident-online-fashion-store-that-deals-on-both-men-women-hats',
    image:
      'https://api1.ngd.africa/media/uploads/images/TRIDENT_BRAND_IDENTITY-07_cg5zWyj',
    name: 'TRIDENT - (Online Fashion Store that Deals on Both Men & Women Hats)',
  },
  {
    link: 'https://youdesign.africa/design-works/naheyla-the-flower-girl',
    image: 'https://api1.ngd.africa/media/uploads/images/character-design-02',
    name: 'Naheyla, the flower girl',
  },
  {
    link: 'https://youdesign.africa/design-works/brand-identity-for-buxari-games',
    image: 'https://api1.ngd.africa/media/uploads/images/1_TFmmqUS',
    name: 'Brand Identity for Buxari Games',
  },
  {
    link: 'https://youdesign.africa/design-works/health-healthy-living-for-mood-massage-flyer-design',
    image:
      'https://api1.ngd.africa/media/uploads/images/DAY-3---MASSAGE-SPA-FLYER',
    name: 'HEALTH & HEALTHY LIVING FOR MOOD MASSAGE - FLYER DESIGN',
  },
  {
    link: 'https://youdesign.africa/design-works/intel-logo-animation-day-03',
    image: 'https://api1.ngd.africa/media/uploads/images/intel_logo.gif',
    name: 'Intel logo animation - Day 03',
  },
  {
    link: 'https://youdesign.africa/design-works/christmas-greetings-a-merry-christmas-flyer',
    image:
      'https://api1.ngd.africa/media/uploads/images/20220112_184113_mVtYbBC',
    name: 'Christmas greetings: A merry Christmas flyer',
  },
];
