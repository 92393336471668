export const GET_WITHDRAWALS_QUERY = `
query GetWithdrawals {
    withdrawals {
      id
      user {
        id
        names
        firstName
        lastName
        username
        userId
        email
        profileImage
      }
      amount
      status
      createdAt
    }
  }`;
