<h1 class="text-2xl font-light text-secondary-900 mb-8">Withdrawals</h1>

<div class="space-y-6">
    <!-- Filter and Download -->
    <div class="flex items-center gap-x-3">
        <app-wtable-filter (onFilter)="setFilters($event)"></app-wtable-filter>
        <button class="btn-plain">
            <ng-icon name="remixDownload2Line"></ng-icon>

            <span>Download</span>
        </button>
    </div>
    <!--  -->

    <!-- Table Search -->
    <div class="flex items-center gap-2 flex-wrap w-full">
        <div class="relative flex items-center">
            <ng-icon class="absolute left-3 top-[25%] text-gray-400" name="remixSearch2Line"></ng-icon>

            <input type="text" [(ngModel)]="searchValue" placeholder="Search"
                class="block w-full py-1.5 pr-5 pl-10 text-gray-700 bg-white rounded-md placeholder-gray-400/70  ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500">
        </div>
        <app-list-select [data]="searchFilters" (selectedList)="setLocale($event)"></app-list-select>
    </div>
    <!--  -->

    <div class="overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden border border-gray-200  md:rounded-md">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-secondary-100/10">
                        <tr>
                            <th sortable="name" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Name
                            </th>

                            <th sortable="email" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Email
                            </th>

                            <th sortable="date" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Date
                            </th>

                            <th sortable="amount" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Amount
                            </th>

                            <th sortable="status" scope="col" (sort)="onSort($event)"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Status
                            </th>

                            <th scope="col"
                                class="uppercase px-4 py-3.5 text-sm font-semibold text-left rtl:text-right text-gray-500">
                                Actions
                            </th>
                        </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                            *ngFor="let withdrawal of withdrawals | withdrawalsSort:searchValue:searchLocale:tableFilters">
                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                <div>
                                    <h2 class="font-medium text-gray-800">
                                        {{withdrawal.name}}
                                    </h2>
                                </div>
                            </td>
                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                {{withdrawal.email}}
                            </td>
                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <div>
                                    <h4 class="text-gray-700 font-semibold">{{withdrawal.date}}
                                    </h4>
                                </div>
                            </td>
                            <td class="px-4 py-4 text-sm font-semibold whitespace-nowrap">
                                {{withdrawal.amount}}
                            </td>

                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <div
                                    [class]="'font-semibold py-1 px-2 w-fit rounded-full text-xs uppercase '.concat(getColor(withdrawal.status))">
                                    {{withdrawal.status}}
                                </div>
                            </td>

                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <button (click)="openWithdrawalModal({ state: true , user: withdrawal})"><ng-icon
                                        name="remixEyeFill" class="text-gray-500"></ng-icon></button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="flex items-center justify-between ">
        <div class="text-sm text-gray-500">
            Page <span class="font-medium text-gray-700">1 of 10</span>
        </div>

        <div class="flex items-center mt-4 gap-x-4 sm:mt-0">
            <a href="#"
                class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100">

                <ng-icon name="remixArrowLeftLine"></ng-icon>

                <span>
                    previous
                </span>
            </a>

            <a href="#"
                class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100">
                <span>
                    Next
                </span>

                <ng-icon name="remixArrowRightLine"></ng-icon>
            </a>
        </div>
    </div>
</div>

<!-- Withdrawal Confirmation Modal -->
<app-dialog (close)="closeModal()" [open]="open">
    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                <ng-icon class="text-primary-600" name="remixWallet3Line"></ng-icon>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Approve withdrawal</h3>
                <p class="text-gray-500 py-4">Approve {{currentUser?.name}} -
                    <span class="font-bold">{{currentUser?.amount}}</span> withdrawal</p>
                <div class="flex flex-wrap items-center gap-2">
                    <button type="button" class="btn-secondary font-semibold">Approve</button>
                    <button type="button" class="btn-plain font-semibold">Mark as Paid</button>
                    <button type="button"
                        class="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-fit">Reject</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button (click)="open = false" type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
    </div>
</app-dialog>
<!--  -->