import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PageLayout } from "../../types";

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private layoutSubject = new BehaviorSubject<PageLayout>(PageLayout.Blank)

    public layout$ = this.layoutSubject.asObservable()

    setLayout(value: PageLayout) {
        this.layoutSubject.next(value)
    }
}