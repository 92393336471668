import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { PageLayout } from '../../types';
import { inject } from '@angular/core';
import { LayoutService } from './layout.service';

export const setLayout = (layout: PageLayout): ResolveFn<void> => {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    inject(LayoutService).setLayout(layout)
  };
};
