import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '../../ui/sidebar/sidebar.component';
import { TopbarComponent } from '../../ui/topbar/topbar.component';
import { Page } from '../../../types';

export const allPages: Page[] = [
  {
    title: 'Dashboard',
    icon: 'remixHome2Line',
    link: '/',
  },
  {
    title: 'User Management',
    icon: 'remixUser3Line',
    link: '/users',
    subPages: [
      {
        title: 'Freelancers',
        link: '/users/freelancers',
      },
      {
        title: 'Clients',
        link: '/users/clients',
      },
    ],
  },
  {
    title: 'Withdrawals',
    icon: 'remixWallet3Line',
    link: '/withdrawals',
  },
  {
    title: 'Portfolios',
    icon: 'remixBox2Line',
    link: '/portfolios',
  },
  {
    title: 'Activities',
    icon: 'remixBarChartGroupedLine',
    link: '/activity-logs',
  },
  {
    title: 'Reporting',
    icon: 'remixStickyNoteLine',
    link: '/reporting',
    subPages: [
      {
        title: 'Report Dashboard',
        link: '/report/dashboard',
      },
      {
        title: 'Report Generator',
        link: '/report/generator',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'remixSettings2Line',
    link: '/settings',
  },
  {
    title: 'Help & Support',
    icon: 'remixQuestionAnswerFill',
    link: '/help',
    subPages: [
      {
        title: 'Knowledge Base',
        link: '/help/knowledge-base',
      },
      {
        title: 'Support Ticket System',
        link: '/help/support-tickets',
      },
    ],
  },
];

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, SidebarComponent, TopbarComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit {
  pages: Page[] = allPages;
  collapse: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const {
        open,
      }: {
        open: boolean;
      } =
        localStorage.getItem('ngd-admin') &&
        JSON.parse(localStorage.getItem('ngd-admin')!).open
          ? JSON.parse(localStorage.getItem('ngd-admin')!)
          : { open: false };

      this.collapse = open;
    }
  }

  applyWidth(collapsed: boolean) {
    this.collapse = collapsed;
  }
}
