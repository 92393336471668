export const clientsData = [
  {
    name: 'ABC Corporation',
    amountSpent: '500000',
    jobsPosted: 5,
    business: 'Construction',
    email: 'contact@abccorp.com',
    username: 'abc_corp',
  },
  {
    name: 'XYZ Inc.',
    amountSpent: '350000',
    jobsPosted: 3,
    business: 'Start-up',
    email: 'info@xyzinc.com',
    username: 'xyz_inc',
  },
  {
    name: 'John Doe',
    amountSpent: '20000',
    jobsPosted: 1,
    business: 'Individual Client',
    email: 'john.doe@example.com',
    username: 'john_doe',
  },
  {
    name: '123 Enterprises',
    amountSpent: '450000',
    jobsPosted: 8,
    business: 'News Agency',
    email: 'contact@123enterprises.com',
    username: '123_ent',
  },
  {
    name: 'LMN Ltd.',
    amountSpent: '600000',
    jobsPosted: 6,
    business: 'Medicine',
    email: 'info@lmnltd.com',
    username: 'lmn_ltd',
  },
  {
    name: 'Jane Smith',
    amountSpent: '30000',
    jobsPosted: 2,
    business: 'Individual Client',
    email: 'jane.smith@example.com',
    username: 'jane_smith',
  },
  {
    name: 'EFG Corporation',
    amountSpent: '400000',
    jobsPosted: 7,
    business: 'Start-up',
    email: 'hello@efgcorp.com',
    username: 'efg_corp',
  },
  {
    name: 'GHI Solutions',
    amountSpent: '250000',
    jobsPosted: 5,
    business: 'News Agency',
    email: 'contact@ghisolutions.com',
    username: 'ghi_sol',
  },
  {
    name: 'Peter Johnson',
    amountSpent: '35000',
    jobsPosted: 2,
    business: 'Individual Client',
    email: 'peter.johnson@example.com',
    username: 'peter_johnson',
  },
  {
    name: 'OPQ Ltd.',
    amountSpent: '700000',
    jobsPosted: 6,
    business: 'Construction',
    email: 'info@opqltd.com',
    username: 'opq_ltd',
  },
  {
    name: 'MNO Enterprises',
    amountSpent: '550000',
    jobsPosted: 5,
    business: 'Start-up',
    email: 'contact@mnoenterprises.com',
    username: 'mno_ent',
  },
  {
    name: 'ABC Innovations',
    amountSpent: '150000',
    jobsPosted: 4,
    business: 'Medicine',
    email: 'info@abcinnovations.com',
    username: 'abc_innov',
  },
  {
    name: 'Mr. Adamu',
    amountSpent: '40000',
    jobsPosted: 3,
    business: 'Individual Client',
    email: 'mr.adamu@example.com',
    username: 'mr_adamu',
  },
  {
    name: 'XYZ Enterprises',
    amountSpent: '300000',
    jobsPosted: 6,
    business: 'News Agency',
    email: 'info@xyzenterprises.com',
    username: 'xyz_ent',
  },
  {
    name: 'Mr. Olufemi',
    amountSpent: '25000',
    jobsPosted: 2,
    business: 'Individual Client',
    email: 'mr.olufemi@example.com',
    username: 'mr_olufemi',
  },
  {
    name: 'LMN Innovations',
    amountSpent: '600000',
    jobsPosted: 7,
    business: 'Construction',
    email: 'info@lmninnovations.com',
    username: 'lmn_innov',
  },
  {
    name: 'Mrs. Ahmed',
    amountSpent: '30000',
    jobsPosted: 1,
    business: 'Individual Client',
    email: 'mrs.ahmed@example.com',
    username: 'mrs_ahmed',
  },
  {
    name: 'PQR Solutions',
    amountSpent: '450000',
    jobsPosted: 5,
    business: 'Medicine',
    email: 'contact@pqrsolutions.com',
    username: 'pqr_sol',
  },
  {
    name: 'Dr. Okafor',
    amountSpent: '50000',
    jobsPosted: 3,
    business: 'Individual Client',
    email: 'dr.okafor@example.com',
    username: 'dr_okafor',
  },
  {
    name: 'EFG Enterprises',
    amountSpent: '400000',
    jobsPosted: 6,
    business: 'Start-up',
    email: 'contact@efgenterprises.com',
    username: 'efg_ent',
  },
  {
    name: 'GHI Technologies',
    amountSpent: '200000',
    jobsPosted: 4,
    business: 'News Agency',
    email: 'info@ghitechnologies.com',
    username: 'ghi_tech',
  },
  {
    name: 'Mrs. Yemi',
    amountSpent: '60000',
    jobsPosted: 2,
    business: 'Individual Client',
    email: 'mrs.yemi@example.com',
    username: 'mrs_yemi',
  },
  {
    name: 'JKL Ltd.',
    amountSpent: '350000',
    jobsPosted: 5,
    business: 'Construction',
    email: 'info@jkltd.com',
    username: 'jkl_ltd',
  },
  {
    name: 'Mr. Bello',
    amountSpent: '20000',
    jobsPosted: 1,
    business: 'Individual Client',
    email: 'mr.bello@example.com',
    username: 'mr_bello',
  },
  {
    name: 'RST Enterprises',
    amountSpent: '750000',
    jobsPosted: 6,
    business: 'Medicine',
    email: 'info@rstenterprises.com',
    username: 'rst_ent',
  },
  {
    name: 'Mrs. Ahmed',
    amountSpent: '40000',
    jobsPosted: 3,
    business: 'Individual Client',
    email: 'mrs.ahmed2@example.com',
    username: 'mrs_ahmed2',
  },
  {
    name: 'OPQ Innovations',
    amountSpent: '600000',
    jobsPosted: 7,
    business: 'Construction',
    email: 'info@opqinnovations.com',
    username: 'opq_innov',
  },
  {
    name: 'Dr. Yerima',
    amountSpent: '55000',
    jobsPosted: 2,
    business: 'Individual Client',
    email: 'dr.yerima@example.com',
    username: 'dr_yerima',
  },
  {
    name: 'MNO Corporation',
    amountSpent: '450000',
    jobsPosted: 5,
    business: 'Start-up',
    email: 'info@mnocorporation.com',
    username: 'mno_corp',
  },
  {
    name: 'ABC Ltd.',
    amountSpent: '200000',
    jobsPosted: 4,
    business: 'News Agency',
    email: 'info@abcltd.com',
    username: 'abc_ltd',
  },
  {
    name: 'XYZ Solutions',
    amountSpent: '300000',
    jobsPosted: 6,
    business: 'Construction',
    email: 'info@xyzsolutions.com',
    username: 'xyz_sol',
  },
];

export const clientBusinesses: string[] = [
  "Construction",
  "Start-up",
  "Individual Client",
  "News Agency",
  "Medicine"
];
