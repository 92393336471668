import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEdit2Line, remixEyeLine } from '@ng-icons/remixicon';
import { createPopper } from '@popperjs/core';
import { clientBusinesses } from '../../../clients-data';
import { CfilterConfig } from '../../../types';
import { MultiSelectComponent } from '../multi-select/multi-select.component';

@Component({
  selector: 'app-ctable-filter',
  standalone: true,
  imports: [NgClass, FormsModule, NgIconComponent, MultiSelectComponent],
  providers: [provideIcons({ remixEyeLine, remixEdit2Line })],
  templateUrl: './ctable-filter.component.html',
})
export class CtableFilterComponent implements AfterViewInit {
  @Output() onFilter  = new EventEmitter<CfilterConfig>()
  skillsData: string[] = clientBusinesses;
  dropdownPopoverShow = false;
  clearSkills = false

  jobsPostedFrom: number = 0
  jobsPostedTo?: number 
  businessAreas: string[] = []

  @ViewChild('btnDropdownRef', { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild('popoverDropdownRef', { static: true })
  popoverDropdownRef!: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: 'bottom-start',
      }
    );
  }

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  setSelectedLists(lists: string[]) {
    this.businessAreas = lists
  }

  applyFilters() {
    this.onFilter.emit({
      jobsPosted: { from: this.jobsPostedFrom, to: this.jobsPostedTo },
      businessAreas: this.businessAreas,
    })

    this.dropdownPopoverShow = false
  }

  clearFilters() {
    this.clearSkills = true

    setTimeout(() => this.clearSkills = false, 500)

    this.jobsPostedFrom = 0
    this.jobsPostedTo = undefined
    this.businessAreas = []
    
  }
}
