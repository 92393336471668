import { Pipe, PipeTransform } from '@angular/core';
import { FilterConfig, Freelancer } from '../../../types';

@Pipe({ name: 'freelancersSort', standalone: true })
export class FreelancersSortPipe implements PipeTransform {
  transform(
    values: Freelancer[],
    search: string,
    locale: 'all' | 'email' | 'name' | 'username',
    filters: FilterConfig
  ): Freelancer[] {
    let searchResults;
    if (values.length === 0) {
      return values;
    }

    searchResults = values.filter((value: Freelancer) => {
      const nameFound =
        value.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const emailFound =
        value.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const usernameFound =
        value.username.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      if (locale === 'all') {
        if (nameFound || emailFound || usernameFound) {
          return value;
        }
      }

      if (locale === 'name') {
        if (nameFound) {
          return value;
        }
      }

      if (locale === 'email') {
        if (emailFound) {
          return value;
        }
      }

      if (locale === 'username') {
        if (usernameFound) {
          return value;
        }
      }

      return;
    });

    if (!search || search.length === 0) {
      searchResults = values;
    }

    if (
      filters.rating.from === 0 &&
      filters.rating.to === 5 &&
      filters.jobsDone.from === 0 &&
      !filters.jobsDone.to &&
      filters.skills.length === 0
    ) {
      return searchResults;
    }

    const filterResults = searchResults.filter((value: Freelancer) => {
      const amongRating =
        value.rating >= filters.rating.from &&
        value.rating <= filters.rating.to;

      let amongJobsDone;

      if (filters.jobsDone.to) {
        amongJobsDone =
          value.jobsDone >= filters.jobsDone.from &&
          value.jobsDone <= filters.jobsDone.to;
      } else amongJobsDone = value.jobsDone >= filters.jobsDone.from;

      let amongSkills;

      if (filters.skills.length === 0) {
        amongSkills = true;
      } else {
        amongSkills = value.skills.some((s) => filters.skills.includes(s));
      }

      if (amongRating && amongJobsDone && amongSkills) return value;
      else return;
    });

    return filterResults;
  }
}
