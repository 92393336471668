export enum PageLayout {
  Blank = 'blank',
  Center = 'center',
  Main = 'main',
}
// Sidebar types
export interface Page {
  title: string;
  icon: string;
  link?: string;
  subPages?: SubPage[];
}

export interface SubPage {
  title: string;
  link: string;
}

export interface Freelancer {
  id: string;
  name: string;
  email: string;
  username: string;
  jobsDone: number;
  earning: string;
  skills: string[];
  rating: number;
}

export interface FilterConfig {
  rating: {
    from: number;
    to: number;
  };
  jobsDone: {
    from: number;
    to?: number;
  };
  skills: string[];
}

export interface CfilterConfig {
  jobsPosted: {
    from: number;
    to?: number;
  };
  businessAreas: string[];
}

export interface WfilterConfig {
  status: string[];
}


export interface Client {
  name: string;
  amountSpent: string;
  jobsPosted: number;
  business: string;
  email: string;
  username: string;
}

export interface Withdrawal {
  name: string;
  email: string;
  username: string;
  date: string;
  amount: string;
  status: 'pending' | 'paid' | 'rejected';
}

export interface Portfolio {
  name: string;
  link: string;
  image: string;
}

export interface ScaleType {
  (d: object): any,
  range: Function,
  domain: Function,
  ticks: Function,
}

export interface DimensionsType {
marginTop: number
marginRight: number
marginBottom: number
marginLeft: number
height: number
width: number
boundedHeight?: number
boundedWidth?: number
}

export interface AccessorType {
(value: any): any
}