export const USER_LOGIN_MUTATION = `
mutation LoginUserMutation($email: String!, $password: String!) {
  adminLogin(
    email: $email,
    password: $password
  ) {
    token
    user {
      id
      lastLogin
      isSuperuser
      firstName
      lastName
      dateJoined
      username
      email
    }
  }
}
`;

export const VERIFY_TOKEN_MUTATION = `
mutation VerifyUser($token: String) {
  verifyToken(token: $token) {
    payload
  }
}
`;
