<ng-container *ngIf="!loading; else loadingEl" [ngSwitch]="layoutService.layout$ | async">
  <router-outlet *ngSwitchCase="PageLayout.Blank"></router-outlet>

  <app-centered-layout *ngSwitchCase="PageLayout.Center">
    <router-outlet></router-outlet>
  </app-centered-layout>

  <app-main-layout *ngSwitchCase="PageLayout.Main">
    <router-outlet></router-outlet>
  </app-main-layout>
</ng-container>

<ng-template #loadingEl>
  <div class="inset-0 h-screen w-screen bg-white flex justify-center items-center">
    <app-page-spinner></app-page-spinner>
  </div>
</ng-template>