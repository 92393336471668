import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-centered-layout',
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <div
      class="flex justify-center w-full h-full min-h-screen bg-white md:bg-secondary-700"
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class CenteredLayoutComponent {}
