import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEdit2Line, remixEyeLine } from '@ng-icons/remixicon';
import { createPopper } from '@popperjs/core';
import { WfilterConfig } from '../../../types';
import { MultiSelectComponent } from '../multi-select/multi-select.component';

@Component({
  selector: 'app-wtable-filter',
  standalone: true,
  imports: [NgClass, FormsModule, NgIconComponent, MultiSelectComponent],
  providers: [provideIcons({ remixEyeLine, remixEdit2Line })],
  templateUrl: './wtable-filter.component.html',
})
export class WtableFilterComponent implements AfterViewInit {
  @Output() onFilter  = new EventEmitter<WfilterConfig>()
  statusData: string[] = ['pending', 'paid', 'rejected']
  dropdownPopoverShow = false;
  clearStatuses = false

  statuses: string[] = []

  @ViewChild('btnDropdownRef', { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild('popoverDropdownRef', { static: true })
  popoverDropdownRef!: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: 'bottom-start',
      }
    );
  }

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  setSelectedLists(lists: string[]) {
    this.statuses = lists
  }

  applyFilters() {
    this.onFilter.emit({
      status: this.statuses
    })

    this.dropdownPopoverShow = false
  }

  clearFilters() {
    this.clearStatuses = true

    setTimeout(() => this.clearStatuses = false, 500)

    this.statuses = []
    
  }
}
