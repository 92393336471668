import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiRequest } from '../utils/api-request';
import { USER_LOGIN_MUTATION } from '../utils/graphql/auth-mutations';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  login(email: string, password: string): Observable<any> {
    const req = ApiRequest(
      USER_LOGIN_MUTATION,
      { email, password },
      "adminLogin"
    );

    return from(req);
  }

  /* logout(): Observable<any> {
  } */
}
