<div *ngIf="open" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div (click)="closeModal()" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-in-out duration-300"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>