<div class="px-4 mt-10">
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white order-0"
  >
    <div class="flex-auto px-4 sm:px-10 py-8">
      <div class="mb-10 space-y-1">
        <img
          alt="ngd logo"
          src="../../../assets/ngd-logo.png"
          class="h-12 w-auto"
        />
        <h6 class="text-secondary-700 text-xl font-bold tracking-tight">
          Create an account
        </h6>
        <p class="text-sm text-gray-400">
          Enter your name, email and password to continue
        </p>
      </div>

      <form>
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-secondary-600 text-xs font-bold mb-2"
            htmlFor="grid-name"
          >
            Name
          </label>
          <input
            name="grid-name"
            type="text"
            class="border-0 px-3 py-3 placeholder-secondary-400 text-gray-600 bg-white rounded text-sm ring-1 ring-gray-300 focus:outline-none focus:ring focus:ring-secondary-200 w-full ease-linear transition-all duration-150"
            placeholder="Enter your name"
          />
        </div>

        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-secondary-600 text-xs font-bold mb-2"
            htmlFor="grid-email"
          >
            Email
          </label>
          <input
          name="grid-email"
            type="email"
            class="border-0 px-3 py-3 placeholder-secondary-400 text-gray-600 bg-white rounded text-sm ring-1 ring-gray-300 focus:outline-none focus:ring focus:ring-secondary-200 w-full ease-linear transition-all duration-150"
            placeholder="Enter your email"
          />
        </div>

        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-secondary-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Password
          </label>
          <input
            type="password"
            name="grid-password"
            class="border-0 px-3 py-3 placeholder-secondary-400 text-gray-600 bg-white rounded text-sm ring-1 ring-gray-300 focus:outline-none focus:ring focus:ring-secondary-200 w-full ease-linear transition-all duration-150"
            placeholder="Enter your password"
          />
        </div>

        <div class="text-center mt-6">
          <button
            class="flex items-center justify-center gap-2 bg-primary-500 text-white active:bg-primary-600 text-sm font-bold uppercase px-6 py-3 mb-4 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full ease-linear transition-all duration-150 disabled:opacity-70"
            type="button"
            (click)="setLoading(true)"
            [disabled]="loading"
          >
            <app-chase-spinner *ngIf="loading"></app-chase-spinner>
            <span>SIGN UP</span>
          </button>
        </div>

        <div
        class="flex items-center justify-center bg-gray-200 text-xs font-semibold px-6 py-4 rounded-md w-full">
        <span class="text-gray-500">Already registered?&nbsp;</span>
        <a [routerLink]="['/login']" class="text-gray-800">
          Sign in
        </a>
        
      </div>
      </form>
    </div>
  </div>
</div>
