import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DashboardCardsComponent } from '../../ui/dashboard-cards/dashboard-cards.component';
import { TopSearchesComponent } from '../../ui/top-searches/top-searches.component';
import { RevenueChartComponent } from '../../ui/charts/revenue-chart/revenue-chart.component';
import { ApiRequest } from '../../utils/api-request';
import { GET_METRICS_QUERY } from '../../utils/graphql/metrics-queries';
import { from } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export type Metrics = {
  completedJobs: number;
  freelancers: number;
  satisfiedBrands: number;
  payouts: number;
};

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    DashboardCardsComponent,
    RevenueChartComponent,
    TopSearchesComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  metrics: Metrics | null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      from(ApiRequest(GET_METRICS_QUERY, {}, 'systemMetrics')).subscribe({
        next: (data) => {
          this.metrics = data;
        },
        error: (err) => {
          console.log('Error', err);
        },
      });
    }
  }
}
