import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ChaseSpinnerComponent } from '../../ui/spinners/chase-spinner/chase-spinner.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [RouterLink, ChaseSpinnerComponent, NgIf],
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  loading = false;

  setLoading(state: boolean) {
    setTimeout(() => (this.loading = state), 500);
  }
}
