import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  @Input({ required: true }) open: boolean = false
  @Output() close = new EventEmitter()

  closeModal() {
    this.close.emit()
  }
}
