import { PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserStoreService } from '../services/user-store.service';
import { isPlatformServer } from '@angular/common';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree | Observable<boolean> => {
  const authService = inject(UserStoreService);
  const router = inject(Router)

  if (isPlatformServer(inject(PLATFORM_ID))) {
    return of(true);
  }
  
  return authService.isLoggedIn() || router.parseUrl('/login')
};
