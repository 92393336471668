<div class="px-4 mt-10">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white order-0">
    <div class="flex-auto px-4 sm:px-10 py-8">
      <div class="mb-10 space-y-1">
        <img alt="ngd logo" src="../../../assets/ngd-logo.png" class="h-12 w-auto" />
        <h6 class="text-secondary-700 text-xl font-bold tracking-tight">
          Welcome back!
        </h6>
        <p class="text-sm text-gray-400">
          Enter your email and password to continue
        </p>
      </div>

      <form (ngSubmit)="login()">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-secondary-600 text-xs font-bold mb-2" htmlFor="grid-email">
            Email
          </label>
          <input type="email" name="email"
            class="border-0 px-3 py-3 placeholder-secondary-400 text-gray-600 bg-white rounded text-sm ring-1 ring-gray-300 focus:outline-none focus:ring focus:ring-secondary-200 w-full ease-linear transition-all duration-150"
            placeholder="Enter your email" [(ngModel)]="form.email" />
        </div>

        <div class="relative w-full mb-3">
          <label class="block uppercase text-secondary-600 text-xs font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input type="password" name="password"
            class="border-0 px-3 py-3 placeholder-secondary-400 text-gray-600 bg-white rounded text-sm ring-1 ring-gray-300 focus:outline-none focus:ring focus:ring-secondary-200 w-full ease-linear transition-all duration-150"
            placeholder="Enter your password" [(ngModel)]="form.password" #passwordElement />

          <span class="absolute right-4 top-1/2 cursor-pointer m-0 p-0" (click)="toggleShowPassword(passwordElement)">
            <ng-icon class="text-gray-500" name="remixEyeCloseFill" *ngIf="showPassword; else slash"></ng-icon>
            <ng-template #slash><ng-icon class="text-gray-500" name="remixEyeFill"></ng-icon></ng-template>
          </span>
        </div>
        <div class="flex justify-between flex-wrap items-center">
          <label class="inline-flex items-center cursor-pointer">
            <input id="customCheckLogin" type="checkbox"
              class="form-checkbox border-0 text-secondary-700 ml-1 w-5 h-4 ease-linear transition-all duration-150 rounded" />
            <span class="ml-2 text-xs font-semibold text-secondary-600">
              Remember me
            </span>
          </label>

          <div>
            <a href="javascript:void(0)" class="text-secondary-600">
              <small>Forgot password?</small>
            </a>
          </div>
        </div>

        <div class="text-center mt-6">
          <button
            class="flex items-center justify-center gap-2 bg-primary-500 text-white active:bg-primary-600 text-sm mb-4 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full ease-linear transition-all duration-150 disabled:opacity-70"
            type="submit" [disabled]="loading">
            <app-chase-spinner *ngIf="loading"></app-chase-spinner>
            <span>Sign In</span>
          </button>
        </div>

        <div class="flex items-center justify-center bg-gray-200 text-xs font-semibold px-6 py-4 rounded-md w-full">
          <span class="text-gray-500">Don't have an account?&nbsp;</span>
          <a [routerLink]="['/register']" class="text-gray-800">
            Get started
          </a>

        </div>
      </form>
    </div>

  </div>
</div>