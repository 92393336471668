<button (blur)="closePopover($event)" class="text-gray-500 block py-1 px-3" (click)="toggleDropdown($event)"
    #btnDropdownRef>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
    </svg>
</button>

<div (blur)="closePopover($event)" class="popel min-w-48 z-50" #popoverDropdownRef>
    <div class="popel bg-white text-base float-left py-2 list-none rounded shadow-lg mt-1 min-w-48"
        [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
        <button
            class="popel flex justify-between items-center text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 transition ease-in-out">
            Edit
            <ng-icon name="remixEdit2Line"></ng-icon>
        </button>
        <button
            class="popel flex justify-between items-center text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 transition ease-in-out">
            View Full Profile

            <ng-icon name="remixEyeLine"></ng-icon>
        </button>
        <div class="popel h-0 my-2 border border-solid border-gray-100"></div>

        <button (click)="emitDeactivate()"
            class="popel text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-red-100 text-red-700 hover:bg-red-200 transition ease-in-out">
            Suspend Account
        </button>

        <div class="popel h-0 my-2 border border-solid border-gray-100"></div>

        <button
            class="popel flex gap-2 items-center uppercase text-left text-sm py-2 px-4 font-bold w-full whitespace-nowrap text-primary-700 bg-primary-100 hover:bg-primary-200 transition ease-in-out">
            Upgrade to Studio

            <ng-icon size="1.1rem" name="remixArrowUpCircleFill"></ng-icon>
        </button>
    </div>
</div>