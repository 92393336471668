import { NgClass } from '@angular/common';
import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { remixEyeLine, remixEdit2Line } from '@ng-icons/remixicon';
import { createPopper } from '@popperjs/core';
import { FilterConfig, Freelancer } from '../../../types';
import { MultiSelectComponent } from '../multi-select/multi-select.component';
import { designFields } from '../../../freelancers-data';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-table-filter',
  standalone: true,
  imports: [NgClass, FormsModule, NgIconComponent, MultiSelectComponent],
  providers: [provideIcons({ remixEyeLine, remixEdit2Line })],
  templateUrl: './table-filter.component.html',
})
export class TableFilterComponent implements AfterViewInit {
  @Output() onFilter  = new EventEmitter<FilterConfig>()
  skillsData: string[] = designFields;
  dropdownPopoverShow = false;
  clearSkills = false

  ratingFrom: number = 0
  ratingTo: number = 5
  jobsDoneFrom: number = 0
  jobsDoneTo?: number
  skills: string[] = []

  @ViewChild('btnDropdownRef', { static: false }) btnDropdownRef!: ElementRef;
  @ViewChild('popoverDropdownRef', { static: true })
  popoverDropdownRef!: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: 'bottom-start',
      }
    );
  }

  toggleDropdown(event: MouseEvent) {
    event.preventDefault();

    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  setSelectedLists(lists: string[]) {
    this.skills = lists
  }

  applyFilters() {
    this.onFilter.emit({
      rating: { from: this.ratingFrom, to: this.ratingTo },
      jobsDone: { from: this.jobsDoneFrom, to: this.jobsDoneTo },
      skills: this.skills,
    })

    this.dropdownPopoverShow = false
  }

  clearFilters() {
    this.clearSkills = true

    setTimeout(() => this.clearSkills = false, 500)

    this.ratingFrom = 0
    this.ratingTo = 5
    this.jobsDoneFrom = 0
    this.jobsDoneTo = undefined
    this.skills = []
    
  }
}
