import { Freelancer } from "./types";

export const freelancersData: Freelancer[] = [
  {
    id: '1',
    name: 'Adeola Adekunle',
    email: 'adloa@gmail.com',
    username: 'adeola_adekunle',
    jobsDone: 10,
    earning: '250,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 3.3,
  },
  {
    id: '2',
    name: 'Chinedu Okoro',
    email: 'chinedu@example.com',
    username: 'chinedu_okoro',
    jobsDone: 15,
    earning: '350,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.8,
  },
  {
    id: '3',
    name: 'Ngozi Onyeka',
    email: 'ngozi@example.com',
    username: 'ngozi_onyeka',
    jobsDone: 20,
    earning: '500,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.3,
  },
  {
    id: '4',
    name: 'Olufemi Ogunleye',
    email: 'olufemi@example.com',
    username: 'olufemi_ogunleye',
    jobsDone: 12,
    earning: '300,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.7,
  },
  {
    id: '5',
    name: 'Chinwe Igwe',
    email: 'chinwe@example.com',
    username: 'chinwe_igwe',
    jobsDone: 18,
    earning: '425,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.9,
  },
  {
    id: '6',
    name: 'Tunde Adeyemi',
    email: 'tunde@example.com',
    username: 'tunde_adeyemi',
    jobsDone: 22,
    earning: '600,000',
    skills: ['UI/UX', 'Web Design'],
    rating: 4.6,
  },
  {
    id: '7',
    name: 'Ifeoma Nwosu',
    email: 'ifeoma@example.com',
    username: 'ifeoma_nwosu',
    jobsDone: 14,
    earning: '325,000',
    skills: ['Graphic Design', 'Illustration'],
    rating: 4.4,
  },
  {
    id: '8',
    name: 'Emeka Obi',
    email: 'emeka@example.com',
    username: 'emeka_obi',
    jobsDone: 17,
    earning: '400,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.7,
  },
  {
    id: '9',
    name: 'Funke Adeyemi',
    email: 'funke@example.com',
    username: 'funke_adeyemi',
    jobsDone: 13,
    earning: '275,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.8,
  },
  {
    id: '10',
    name: 'Ibrahim Abdullahi',
    email: 'ibrahim@example.com',
    username: 'ibrahim_abdullahi',
    jobsDone: 19,
    earning: '475,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 3.6,
  },
  {
    id: '11',
    name: 'Aisha Ibrahim',
    email: 'aisha@example.com',
    username: 'aisha_ibrahim',
    jobsDone: 8,
    earning: '200,000',
    skills: ['UI/UX', 'Web Design'],
    rating: 4.2,
  },
  {
    id: '12',
    name: 'Yusuf Mohammed',
    email: 'yusuf@example.com',
    username: 'yusuf_mohammed',
    jobsDone: 25,
    earning: '700,000',
    skills: ['Graphic Design', 'Illustration'],
    rating: 4.9,
  },
  {
    id: '13',
    name: 'Bukola Oladele',
    email: 'bukola@example.com',
    username: 'bukola_oladele',
    jobsDone: 11,
    earning: '275,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.6,
  },
  {
    id: '14',
    name: 'Chiamaka Nwachukwu',
    email: 'chiamaka@example.com',
    username: 'chiamaka_nwachukwu',
    jobsDone: 16,
    earning: '400,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.7,
  },
  {
    id: '15',
    name: 'Femi Adebayo',
    email: 'femi@example.com',
    username: 'femi_adebayo',
    jobsDone: 21,
    earning: '550,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.3,
  },
  {
    id: '16',
    name: 'Chioma Okafor',
    email: 'chioma@example.com',
    username: 'chioma_okafor',
    jobsDone: 9,
    earning: '225,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 4.8,
  },
  {
    id: '17',
    name: 'Abdulahi Salisu',
    email: 'abdulahi@example.com',
    username: 'abdulahi_salisu',
    jobsDone: 14,
    earning: '375,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.4,
  },
  {
    id: '18',
    name: 'Chinonye Ibe',
    email: 'chinonye@example.com',
    username: 'chinonye_ibe',
    jobsDone: 18,
    earning: '450,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.9,
  },
  {
    id: '19',
    name: 'Fatima Abubakar',
    email: 'fatima@example.com',
    username: 'fatima_abubakar',
    jobsDone: 13,
    earning: '350,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.6,
  },
  {
    id: '20',
    name: 'Umaru Bello',
    email: 'umaru@example.com',
    username: 'umaru_bello',
    jobsDone: 20,
    earning: '600,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.7,
  },
  {
    id: '21',
    name: 'Hassan Adamu',
    email: 'hassan@example.com',
    username: 'hassan_adamu',
    jobsDone: 11,
    earning: '325,000',
    skills: ['UI/UX', 'Web Design'],
    rating: 3.6,
  },
  {
    id: '22',
    name: 'Fatima Ibrahim',
    email: 'fatima2@example.com',
    username: 'fatima_ibrahim',
    jobsDone: 15,
    earning: '400,000',
    skills: ['Graphic Design', 'Illustration'],
    rating: 4.2,
  },
  {
    id: '23',
    name: 'Habib Aliyu',
    email: 'habib@example.com',
    username: 'habib_aliyu',
    jobsDone: 17,
    earning: '475,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.8,
  },
  {
    id: '24',
    name: 'Adaeze Okeke',
    email: 'adaeze@example.com',
    username: 'adaeze_okeke',
    jobsDone: 12,
    earning: '300,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.3,
  },
  {
    id: '25',
    name: 'Yakubu Ismail',
    email: 'yakubu@example.com',
    username: 'yakubu_ismail',
    jobsDone: 19,
    earning: '525,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.9,
  },
  {
    id: '26',
    name: 'Rukayat Abdul',
    email: 'rukayat@example.com',
    username: 'rukayat_abdul',
    jobsDone: 14,
    earning: '375,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 4.6,
  },
  {
    id: '27',
    name: 'Mohammed Ibrahim',
    email: 'mohammed@example.com',
    username: 'mohammed_ibrahim',
    jobsDone: 16,
    earning: '450,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.7,
  },
  {
    id: '28',
    name: 'Amaka Okonkwo',
    email: 'amaka@example.com',
    username: 'amaka_okonkwo',
    jobsDone: 20,
    earning: '600,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.4,
  },
  {
    id: '29',
    name: 'Bashir Sani',
    email: 'bashir@example.com',
    username: 'bashir_sani',
    jobsDone: 8,
    earning: '200,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.9,
  },
  {
    id: '30',
    name: 'Toyin Ogundipe',
    email: 'toyin@example.com',
    username: 'toyin_ogundipe',
    jobsDone: 25,
    earning: '700,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.3,
  },
  {
    id: '31',
    name: 'Nneka Nwosu',
    email: 'nneka@example.com',
    username: 'nneka_nwosu',
    jobsDone: 11,
    earning: '325,000',
    skills: ['UI/UX', 'Web Design'],
    rating: 4.8,
  },
  {
    id: '32',
    name: 'Mustapha Abdullahi',
    email: 'mustapha@example.com',
    username: 'mustapha_abdullahi',
    jobsDone: 15,
    earning: '400,000',
    skills: ['Graphic Design', 'Illustration'],
    rating: 3.6,
  },
  {
    id: '33',
    name: 'Adaobi Eze',
    email: 'adaobi@example.com',
    username: 'adaobi_eze',
    jobsDone: 17,
    earning: '475,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.2,
  },
  {
    id: '34',
    name: 'Amina Bala',
    email: 'amina@example.com',
    username: 'amina_bala',
    jobsDone: 12,
    earning: '300,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.7,
  },
  {
    id: '35',
    name: 'Chukwuemeka Okoli',
    email: 'chukwuemeka@example.com',
    username: 'chukwuemeka_okoli',
    jobsDone: 18,
    earning: '450,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.4,
  },
  {
    id: '36',
    name: 'Blessing Ibrahim',
    email: 'blessing@example.com',
    username: 'blessing_ibrahim',
    jobsDone: 9,
    earning: '225,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 4.9,
  },
  {
    id: '37',
    name: 'Mohammed Ahmed',
    email: 'mohammed2@example.com',
    username: 'mohammed_ahmed',
    jobsDone: 14,
    earning: '375,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.6,
  },
  {
    id: '38',
    name: 'Hauwa Abubakar',
    email: 'hauwa@example.com',
    username: 'hauwa_abubakar',
    jobsDone: 16,
    earning: '450,000',
    skills: ['Logo Design', 'Branding'],
    rating: 3.6,
  },
  {
    id: '39',
    name: 'Yusuf Ibrahim',
    email: 'yusuf2@example.com',
    username: 'yusuf_ibrahim',
    jobsDone: 20,
    earning: '600,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.4,
  },
  {
    id: '40',
    name: 'Rita Nnamdi',
    email: 'rita@example.com',
    username: 'rita_nnamdi',
    jobsDone: 8,
    earning: '200,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.8,
  },
  {
    id: '41',
    name: 'Fatima Abdullahi',
    email: 'fatima3@example.com',
    username: 'fatima_abdullahi',
    jobsDone: 25,
    earning: '700,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 4.3,
  },
  {
    id: '42',
    name: 'Kunle Ojo',
    email: 'kunle@example.com',
    username: 'kunle_ojo',
    jobsDone: 11,
    earning: '325,000',
    skills: ['Web Design', 'Illustration'],
    rating: 4.7,
  },
  {
    id: '43',
    name: 'Chika Madu',
    email: 'chika@example.com',
    username: 'chika_madu',
    jobsDone: 15,
    earning: '400,000',
    skills: ['Logo Design', 'Branding'],
    rating: 3.6,
  },
  {
    id: '44',
    name: 'Ahmed Bello',
    email: 'ahmed@example.com',
    username: 'ahmed_bello',
    jobsDone: 17,
    earning: '475,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.2,
  },
  {
    id: '45',
    name: 'Blessing Okonkwo',
    email: 'blessing2@example.com',
    username: 'blessing_okonkwo',
    jobsDone: 12,
    earning: '300,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.6,
  },
  {
    id: '46',
    name: 'Tope Olaleye',
    email: 'tope@example.com',
    username: 'tope_olaleye',
    jobsDone: 18,
    earning: '450,000',
    skills: ['UI/UX', 'Graphic Design'],
    rating: 4.9,
  },
  {
    id: '47',
    name: 'Ifeanyi Okafor',
    email: 'ifeanyi@example.com',
    username: 'ifeanyi_okafor',
    jobsDone: 9,
    earning: '225,000',
    skills: ['Web Design', 'Illustration'],
    rating: 2.9,
  },
  {
    id: '48',
    name: 'Amina Umar',
    email: 'amina2@example.com',
    username: 'amina_umar',
    jobsDone: 14,
    earning: '375,000',
    skills: ['Logo Design', 'Branding'],
    rating: 4.8,
  },
  {
    id: '49',
    name: 'Chukwudi Okoro',
    email: 'chukwudi@example.com',
    username: 'chukwudi_okoro',
    jobsDone: 16,
    earning: '450,000',
    skills: ['Print Design', 'Typography'],
    rating: 4.4,
  },
  {
    id: '50',
    name: 'Bukola Adebayo',
    email: 'bukola2@example.com',
    username: 'bukola_adebayo',
    jobsDone: 20,
    earning: '600,000',
    skills: ['Motion Graphics', 'Animation'],
    rating: 4.9,
  },
];



export const designFields: string[] = [
  'UI/UX',
  'Graphic Design',
  'Web Design',
  'Illustration',
  'Logo Design',
  'Branding',
  'Print Design',
  'Typography',
  'Motion Graphics',
  'Animation',
];

export const designFieldsColors: { [key: string]: string } = {
  'UI/UX': 'text-blue-700 bg-blue-100',
  'Graphic Design': 'text-purple-700 bg-purple-100',
  'Web Design': 'text-green-700 bg-green-100',
  Illustration: 'text-yellow-700 bg-yellow-100',
  'Logo Design': 'text-accent-700 bg-accent-100',
  Branding: 'text-secondary-700 bg-secondary-100',
  'Print Design': 'text-primary-700 bg-primary-100',
  Typography: 'text-gray-700 bg-gray-100',
  'Motion Graphics': 'text-amber-700 bg-amber-100',
  Animation: 'text-cyan-700 bg-cyan-100',
};
