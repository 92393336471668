import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideToastr({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      enableHtml: true,
      iconClasses: {
        error: 'yd-toast-error',
        info: 'yd-toast-info',
        success: 'yd-toast-success',
        warning: 'yd-toast-warning',
      },
      timeOut: 2000
    }),
  ],
};
