<div class="py-2 px-6 bg-white flex items-center shadow-md shadow-black/5 sticky top-0 left-0 z-20">
    <button type="button" class="text-lg text-gray-600 sidebar-toggle" (click)="sidebarToggle($event)">
        <ng-icon name="remixMenuLine"></ng-icon>
    </button>
    <ul class="flex items-center text-sm ml-4">
        <!-- <li class="mr-2">
            <a href="#" class="hover:text-gray-400 font-medium"><span [ngClass]="parentPageExists ? 'text-gray-400' : 'text-gray-800'">{{pTitle}}</span><span *ngIf="parentPageExists" class="text-gray-200">&nbsp;/</span><span
                    *ngIf="parentPageExists">&nbsp;{{sTitle}}</span></a>
        </li> -->
    </ul>
    <ul class="ml-auto flex items-center">
        <li class="mr-1 dropdown">
            <button type="button"
                class="dropdown-toggle text-gray-400 w-8 h-8 rounded flex items-center justify-center hover:bg-gray-50 hover:text-gray-600">
                <ng-icon name="remixSearchLine"></ng-icon>
            </button>
        </li>
        <li *ngIf="user" class="dropdown ml-3 flex items-center gap-2 bg-gray-100 rounded-sm">
            <span class="text-sm text-gray-500 pl-2">{{user.username ? user.username : user.email}}</span>
            <img [src]="user.profileImage ? user.profileImage : '../../../assets/blank-avatar.png'" alt="" class="w-8 h-8 rounded block object-cover align-middle" />
        </li>

        <button (click)="logout()" type="button"
            class="dropdown-toggle text-red-400 w-8 h-8 rounded flex items-center justify-center hover:bg-red-50 hover:text-red-600">
            <ng-icon name="remixLogoutBoxLine"></ng-icon>
        </button>
    </ul>
</div>