<h1 class="text-2xl font-light text-secondary-900 mb-8">Portfolios</h1>

<div class="space-y-6">

    <!-- Table Search -->
    <div class="flex items-center gap-2 flex-wrap w-full">
        <div class="relative flex items-center w-full">
            <ng-icon class="absolute left-3 top-[25%] text-gray-400" name="remixSearch2Line"></ng-icon>

            <input type="text" [(ngModel)]="searchValue" placeholder="Search"
                class="block w-full py-1.5 pr-5 pl-10 text-gray-700 bg-white rounded-md placeholder-gray-400/70  ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500">
        </div>
    </div>
    <!--  -->

    <div class="min-w-full py-2 align-middle">
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
            <div *ngFor="let portfolio of portfolios"
                class="relative bg-white rounded-lg shadow-lg overflow-hidden ring-1 ring-gray-200">
                <img class="w-full h-40 object-cover" [src]="portfolio.image" alt="" />

                <div class="absolute top-2 right-2 flex flex-col gap-2 items-center justify-center px-2 py-1">
                    <button
                        class="h-8 w-8 bg-gray-900 bg-opacity-50 text-gray-50 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </button>
                    <a target="_blank" [href]="portfolio.link">
                        <button
                            class="h-8 w-8 bg-gray-900 bg-opacity-50 text-gray-50 flex items-center justify-center rounded-full">
                            <ng-icon size="1.25rem" name="remixEyeLine"></ng-icon>
                        </button>
                    </a>
                </div>

                <div class="p-4">
                    <h2 class="uppercase tracking-wide mb-2">{{portfolio.name.length > 30 ? portfolio.name.substring(0,
                        27).concat('...') : portfolio.name}}</h2>
                    <div class="flex items-center gap-2 flex-wrap"><button class="flex items-center justify-center w-fit px-5 py-2 text-sm  transition-colors duration-200 0 border rounded-md gap-x-2 hover:bg-gray-800 bg-gray-900 text-gray-200 border-gray-700 shadow-sm">Add to featured</button>

                    </div>

                </div>

            </div>
        </div>
    </div>

</div>

<!-- Withdrawal Confirmation Modal -->
<app-dialog (close)="closeModal()" [open]="open">
    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                <ng-icon class="text-primary-600" name="remixWallet3Line"></ng-icon>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Approve portfolio</h3>
                <p class="text-gray-500 py-4">Approve {{currentPortfolio?.name}} -
                    <span class="font-bold">{{currentPortfolio?.name}}</span> portfolio
                </p>
                <div class="flex flex-wrap items-center gap-2">
                    <button type="button" class="btn-secondary font-semibold">Approve</button>
                    <button type="button" class="btn-plain font-semibold">Mark as Paid</button>
                    <button type="button"
                        class="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-fit">Reject</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button (click)="open = false" type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
    </div>
</app-dialog>
<!--  -->