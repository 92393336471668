import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-select.component.html',
})
export class ListSelectComponent implements OnInit {
  @Input({ required: true }) data!: string[];
  selected!: string
  state: boolean = false

  @Output() readonly selectedList = new EventEmitter<string>()

  ngOnInit(): void {
    this.selected = this.data[0]
  }

  toggleSelect() {
    this.state = !this.state
  }

  closeOnBlur(e: FocusEvent) {
    const target = e.relatedTarget as HTMLElement || { id: '' }


    if(target.id == 'listselect') return
    else this.state = false

  }

  setSelected(list: string) {
    this.selected = list

    this.selectedList.emit(list.toLowerCase())

    this.state = false
  }
}
