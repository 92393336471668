import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-blank-layout',
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `<ng-content></ng-content>`,
})
export class BlankLayoutComponent {}
