export const GET_METRICS_QUERY = `
query getMetrics {
    systemMetrics {
      id
      completedJobs
      freelancers
      satisfiedBrands
      payouts
    }
}`;
