<div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
    <div class="bg-gradient-to-bl from-secondary-500 to-secondary-600 flex gap-2 justify-between items-end p-4 rounded-md">
        <div class="space-y-2">
            <h4 class="text-sm font-bold tracking-wide uppercase text-secondary-200">TOTAL USERS</h4>

            <h2 class="text-3xl font-light tracking-wide uppercase text-white">{{metrics ? metrics.freelancers : '0'}}</h2>

            <h4
                class="bg-secondary-500 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-secondary-50 w-fit">
                <ng-icon class="text-white" name="remixArrowUpFill"></ng-icon><span>0% GROWTH</span>
            </h4>
        </div>

        <div class="bg-secondary-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-secondary-100" name="remixGroupLine"></ng-icon>
        </div>
    </div>

    <div class="bg-gradient-to-bl from-accent-500 to-accent-600 flex gap-2 justify-between items-end p-4 rounded-md">
        <div class="space-y-2">
            <h4 class="text-sm font-bold tracking-wide uppercase text-accent-200">PAYOUTS</h4>

            <h2 class="text-3xl font-light tracking-wide uppercase text-white">₦{{metrics ? metrics.payouts : '0'}}</h2>

            <h4
                class="bg-accent-500 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-accent-50 w-fit">
                <span>CURRENT MONTH</span>
            </h4>
        </div>

        <div class="bg-accent-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-accent-100" name="remixGroupLine"></ng-icon>
        </div>
    </div>

    <div class="bg-gradient-to-bl from-primary-500 to-primary-600 flex gap-2 justify-between items-end p-4 rounded-md">
        <div class="space-y-2">
            <h4 class="text-sm font-bold tracking-wide uppercase text-primary-200">JOB LISTINGS</h4>

            <h2 class="text-3xl font-light tracking-wide uppercase text-white">{{metrics ? metrics.completedJobs : '0'}}</h2>

            <h4
                class="bg-primary-500 px-2 py-1 rounded-full text-xs font-semibold tracking-wide uppercase text-primary-50 w-fit">
                <span>CURRENT MONTH</span>
            </h4>
        </div>

        <div class="bg-primary-700 p-2 rounded-full h-10 w-10 flex justify-center items-center">
            <ng-icon size="1.5rem" class="text-primary-100" name="remixGroupLine"></ng-icon>
        </div>
    </div>
</div>