import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
  remixEyeFill,
  remixWallet3Line,
} from '@ng-icons/remixicon';
import { WfilterConfig, Withdrawal } from '../../../types';
import {
  WsortableHeaderDirective,
  SortEvent,
  compare,
} from '../../directives/wsortable-header/wsortable-header.directive';
import { WithdrawalsSortPipe } from '../../pipes/withdrawals-sort/withdrawals-sort.pipe';
import { WtableFilterComponent } from '../../ui/wtable-filter/wtable-filter.component';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../ui/list-select/list-select.component';
import { from } from 'rxjs';
import { ApiRequest } from '../../utils/api-request';
import { GET_WITHDRAWALS_QUERY } from '../../utils/graphql/withdrawals';

@Component({
  selector: 'app-withdrawals',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    WsortableHeaderDirective,
    WithdrawalsSortPipe,
    FormsModule,
    DialogComponent,
    WtableFilterComponent,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
      remixEyeFill,
      remixWallet3Line,
    }),
  ],
  templateUrl: './withdrawals.component.html',
})
export class WithdrawalsComponent implements OnInit {
  @ViewChildren(WsortableHeaderDirective)
  headers!: QueryList<WsortableHeaderDirective>;

  currentUser?: Withdrawal;
  open: boolean = false;
  data: Withdrawal[] = [];
  withdrawals: Withdrawal[] = [];

  searchLocale: 'all' | 'email' | 'name' | 'username' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username'];
  tableFilters: WfilterConfig = {
    status: [],
  };

  ngOnInit(): void {
    from(ApiRequest(GET_WITHDRAWALS_QUERY, {}, 'withdrawals')).subscribe({
      next: (result) => {
        console.log(result)
        this.data = result
        this.withdrawals = result
      },
      error: (err) => {
        console.log(err)
      },
    });
  }

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'username';
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.withdrawals = this.data;
    } else {
      this.withdrawals = [...this.data].sort((a, b) => {
        const res = compare(a[column], b[column]);

        return direction === 'asc' ? res : -res;
      });
    }
  }

  openWithdrawalModal(value: { state: boolean; user: Withdrawal }) {
    this.open = value.state;
    this.currentUser = value.user;
  }

  closeModal() {
    this.open = false;
  }

  setFilters(filter: WfilterConfig) {
    this.tableFilters = filter;
  }

  getColor(status: string): string {
    if (status == 'pending') return 'text-gray-700 bg-gray-100';
    else if (status == 'paid') return 'text-green-700 bg-green-100';
    else if (status == 'rejected') return 'text-red-700 bg-red-100';
    return 'text-secondary-700 bg-secondary-100';
  }
}
