import { NgFor, NgIf } from '@angular/common';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowLeftLine,
  remixArrowRightLine,
  remixDownload2Line,
  remixFilter3Line,
  remixSearch2Line,
} from '@ng-icons/remixicon';
import { designFieldsColors, freelancersData } from '../../../../freelancers-data';
import {
  SortEvent,
  SortableHeaderDirective,
  compare,
} from '../../../directives/sortable-header/sortable-header.directive';
import { FreelancersSortPipe } from '../../../pipes/freelancers-sort/freelancers-sort.pipe';
import { DialogComponent } from '../../../ui/dialog/dialog.component';
import { ListSelectComponent } from '../../../ui/list-select/list-select.component';
import { StarRatingComponent } from '../../../ui/star-rating/star-rating.component';
import { FtableDropdownComponent } from '../../../ui/ftable-dropdown/ftable-dropdown.component';
import { FilterConfig, Freelancer } from './../../../../types';
import { TableFilterComponent } from '../../../ui/table-filter/table-filter.component';

@Component({
  selector: 'app-freelancers',
  standalone: true,
  imports: [
    NgIconComponent,
    ListSelectComponent,
    NgFor,
    NgIf,
    SortableHeaderDirective,
    FreelancersSortPipe,
    FormsModule,
    FtableDropdownComponent,
    DialogComponent,
    StarRatingComponent,
    TableFilterComponent,
  ],
  providers: [
    provideIcons({
      remixFilter3Line,
      remixDownload2Line,
      remixSearch2Line,
      remixArrowRightLine,
      remixArrowLeftLine,
    }),
  ],
  templateUrl: './freelancers.component.html',
})
export class FreelancersComponent {
  @ViewChildren(SortableHeaderDirective)
  headers!: QueryList<SortableHeaderDirective>;

  currentUser?: Freelancer;
  open: boolean = false;
  data: Freelancer[] = freelancersData;
  freelancers: Freelancer[] = freelancersData;

  searchLocale: 'all' | 'email' | 'name' | 'username' = 'all';
  searchValue: string = '';
  searchFilters: string[] = ['All', 'Email', 'Name', 'Username'];
  tableFilters: FilterConfig = {
    rating: { from: 0, to: 5 },
    jobsDone: { from: 0, to: undefined },
    skills: [],
  };

  setLocale(locale: string) {
    this.searchLocale = locale as 'all' | 'email' | 'name' | 'username';
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.freelancers = this.data;
    } else {
      this.freelancers = [...this.data].sort((a, b) => {
        const res = compare(a[column], b[column]);

        return direction === 'asc' ? res : -res;
      });
    }
  }

  randomColor(skill: string): string {
    return designFieldsColors[skill];
  }

  openDeactivateModal(value: { state: boolean; user: Freelancer }) {
    this.open = value.state;
    this.currentUser = value.user;
  }

  closeModal() {
    this.open = false;
  }

  setFilters(filter: FilterConfig) {
    this.tableFilters = filter
  }
}
