import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  remixArrowRightSLine,
  remixFileChartLine,
  remixHome2Line,
  remixQuestionAnswerFill,
  remixSettings2Line,
  remixStickyNoteLine,
  remixUser3Line
} from '@ng-icons/remixicon';
import { Page } from '../../../../../types';
@Component({
  selector: 'app-sidebar-link',
  standalone: true,
  imports: [NgIconComponent, RouterLink, CommonModule],
  providers: [
    provideIcons({
      remixHome2Line,
      remixArrowRightSLine,
      remixSettings2Line,
      remixUser3Line,
      remixStickyNoteLine,
      remixFileChartLine,
      remixQuestionAnswerFill
    }),
  ],
  templateUrl: './sidebar-link.component.html',
})
export class SidebarLinkComponent {
  @Input({ required: true }) page!: Page;
  @Input({ required: true }) collapse!: boolean
  @Output() emitClick = new EventEmitter<boolean>()
  
  dropdownToggle(item: HTMLDivElement) {
    const sidebarDropdowns = document.querySelectorAll(
      '.sidebar-dropdown-toggle'
    )!;

    if(this.collapse) this.emitClick.emit(true)

    const parent = item.closest('.group')!;

    if (parent.classList.contains('selected')) {
      parent.classList.remove('selected');
    } else {
      sidebarDropdowns.forEach(function (i) {
        i.closest('.group')!.classList.remove('selected');
      });
      parent.classList.add('selected');
    }
  }
}
